import React from 'react';
import { List, ListItemIcon, ListItemText, Collapse, ListItemButton, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from "@mui/icons-material/ExpandMore";
import { MenuItem, separateMenuItems } from "./constants/menuItem";

interface MenuItemsProps {
  items: MenuItem[];
  openSubMenu: string | null;
  handleSubMenuToggle: (text: string) => void;
  handleItemClick: (item: MenuItem) => void;
  selectedItem: string;
  selectedSubItem: string | null;
}

const AllMenuItems: React.FC<MenuItemsProps> = ({ 
  items, 
  openSubMenu, 
  handleSubMenuToggle, 
  handleItemClick, 
  selectedItem, 
  selectedSubItem 
}) => {
  const { mainItems, baseItems } = separateMenuItems(items);

  const renderMenuItem = (item: MenuItem) => {
    const isSelected = selectedItem === item.text;
    
    if (item.subItems) {
      return (
        <React.Fragment key={item.text}>
          <ListItemButton 
            onClick={() => handleSubMenuToggle(item.text)}
            sx={{ 
              paddingLeft: (theme) => theme.spacing(3),
              position: 'relative',
              '& .MuiListItemIcon-root': {
                position: 'relative',
                zIndex: 1,
                color: isSelected ? '#27382C' : 'white',
              },
              '& .MuiListItemIcon-root::before': isSelected ? {
                content: '""',
                position: 'absolute',
                width: '36px',
                height: '36px',
                borderRadius: '50%',
                backgroundColor: '#F4B71D',
                left: '-5.5px',
                top: '-5.5px',
                zIndex: -1,
              } : {},
            }}
          >
            <ListItemIcon>
              {React.createElement(item.icon)}
            </ListItemIcon>
            <ListItemText primary={item.text} />
            {openSubMenu === item.text ? 
              <ExpandLess sx={{ color: 'white' }} /> : 
              <ExpandMore sx={{ color: 'white' }} />}
          </ListItemButton>
          <Collapse in={openSubMenu === item.text} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.subItems.map((subItem) => (
                <ListItemButton
                  key={subItem.text}
                  component={Link}
                  to={subItem.path || ''}
                  onClick={() => handleItemClick(item)}
                  sx={{ 
                    paddingLeft: (theme) => theme.spacing(5),
                    '& .MuiListItemIcon-root': {
                      color: selectedSubItem === subItem.text ? '#F4B71D' : 'white',
                    }
                  }}
                >
                  <ListItemIcon>{React.createElement(subItem.icon)}</ListItemIcon>
                  <ListItemText primary={subItem.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      );
    }

    return (
      <ListItemButton 
        key={item.text} 
        component={item.path ? Link : 'div'} 
        to={item.path} 
        onClick={() => handleItemClick(item)}
        sx={{ 
          color: 'white',
          paddingLeft: (theme) => theme.spacing(3),
          position: 'relative',
          '& .MuiListItemIcon-root': {
            position: 'relative',
            zIndex: 1,
            color: isSelected ? '#27382C' : 'white',
          },
          '& .MuiListItemIcon-root::before': isSelected ? {
            content: '""',
            position: 'absolute',
            width: '36px',
            height: '36px',
            borderRadius: '50%',
            backgroundColor: '#F4B71D',
            left: '-5.5px',
            top: '-5.5px',
            zIndex: -1,
          } : {},
        }}
      >
        <ListItemIcon>
          {React.createElement(item.icon)}
        </ListItemIcon>
        <ListItemText primary={item.text} />
      </ListItemButton>
    );
  };

  return (
    <List sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      padding: 0,
      pb: 2 
    }}>
      <div>
        {mainItems.map((item) => renderMenuItem(item))}
      </div>
      <div style={{ flexGrow: 1 }} />
      <div>
        <Divider sx={{ 
          my: 2, 
          mx: 2.5, 
          backgroundColor: 'rgba(255, 255, 255, 0.4)' 
        }} />
        {baseItems.map((item) => renderMenuItem(item))}
      </div>
    </List>
  );
};

export default AllMenuItems;
