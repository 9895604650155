import React, { useEffect } from "react";
import { Box, Dialog, DialogContent } from "@mui/material";
import ToolbarBottom from "./ToolbarBottom";
import ToolbarTop from "./ToolbarTop";
import ImageEditorStage from "./ImageEditorStage";

export type ImageEditorProps = {
  data: Blob;
};

const ImageEditor: React.FC<ImageEditorProps> = ({ data }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  // trigger rerender when ref size changes (simplest way to do this)
  // (either when full-screen is toggled or when window is resized)
  const [, forceUpdate] = React.useState<unknown>();

  const [isFullscreen, setIsFullscreen] = React.useState(false);

  // listen on resized and update stage
  useEffect(() => {
    const fn = (e: Event) => {
      forceUpdate(e);
    };
    window.addEventListener("resize", fn);

    return () => {
      window.removeEventListener("resize", fn);
    };
  }, []);

  // listen on fullscreen toggle and update stage
  useEffect(() => {
    forceUpdate({});
  }, [isFullscreen]);

  const width = ref.current?.offsetWidth ?? 0;

  const content = (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
      ref={ref}
    >
      <ToolbarTop />
      <ImageEditorStage data={data} width={width} />
      <ToolbarBottom
        isFullscreen={isFullscreen}
        toggleFullscreen={() => setIsFullscreen(!isFullscreen)}
      />
    </Box>
  );

  if (isFullscreen) {
    // There won't be any fade-in/out animations, but this is a good enough
    // and we don't want to mount the content twice just to fix the animations.
    return (
      <Dialog
        open={isFullscreen}
        PaperProps={{
          style: {
            minHeight: "99%",
            minWidth: "99%",
          },
        }}
      >
        <DialogContent>{content}</DialogContent>
      </Dialog>
    );
  }

  return content;
};

export default ImageEditor;
