import React from "react";
import useAnnotations, { Annotation } from "hooks/useAnnotations";
import { KonvaEventObject } from "konva/lib/Node";
import { Stage as StageRef } from "konva/lib/Stage";
import {
  ImageCoordinates,
  IMAGE_TYPES,
  updateImage,
  uploadImage,
  UpdateImageCoordinateParams,
  uploadImageCoordinates,
} from "queries/devices/images";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import useCustomSnackBar from "hooks/useCustomSnackBar";
import { useValidationPanel } from "./ValidationPanelProvider";
import { useChainValidation } from "./ChainValidationProvider";

type ValidationContextProps = {
  handleMouseDown: (e: KonvaEventObject<MouseEvent>) => void;
  handleMouseMove: (e: KonvaEventObject<MouseEvent>) => void;
  handleMouseUp: (e: KonvaEventObject<MouseEvent>) => void;
  // all anotations drawn (should match 1-1 with coordinates)
  annotations: Annotation[];
  // currently drawing the new annotation
  newAnnotation: Annotation | undefined;

  // Annotation / coordinate index to delete
  coordinateIndexToDelete: number | null;
  setCoordinateIndexToDelete: React.Dispatch<React.SetStateAction<number | null>>;
  deleteSelectedCoordinate: () => void;

  onClear: () => void;
  onSave: () => void;
  isSaving: boolean;
  onClose: () => void;
  onChangeVisibility: () => void;
  stageRef: React.MutableRefObject<StageRef | null>;
  setCoordinateList: React.Dispatch<React.SetStateAction<ImageCoordinates>>;
  isVisible?: boolean;
};

const INITIAL_VALUES: ValidationContextProps = {
  handleMouseDown: () => {},
  handleMouseMove: () => {},
  handleMouseUp: () => {},
  annotations: [],
  newAnnotation: undefined,
  onClear: () => {},
  onSave: () => {},
  isSaving: false,
  onClose: () => {},
  onChangeVisibility: () => {},
  stageRef: { current: null },
  setCoordinateList: () => {},
  coordinateIndexToDelete: null,
  setCoordinateIndexToDelete: () => {},
  deleteSelectedCoordinate: () => {},
  isVisible: true,
};

const ValidationContext = React.createContext<ValidationContextProps>(INITIAL_VALUES);
function convertBase64ToBlob(base64Image: string) {
  // Split into two parts
  const parts = base64Image.split(";base64,");

  // Hold the content type
  const imageType = parts[0].split(":")[1];

  // Decode Base64 string
  const decodedData = window.atob(parts[1]);

  // Create UNIT8ARRAY of size same as row data length
  const uInt8Array = new Uint8Array(decodedData.length);

  // Insert all character code into uInt8Array
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }

  // Return BLOB image after conversion
  return new Blob([uInt8Array], { type: imageType });
}

export type ValidationProviderProps = {
  id: string;
  saveAs: IMAGE_TYPES;
  onClose: ValidationContextProps["onClose"];
  initialCoordinates: ImageCoordinates;
  isVisible?: boolean;

  // If the mode is "validation", we make two separate requests to also save:
  // - the coordinates
  // - the number of coordinates as detection count and mark image as validated
  // (if image is opened for validation and not for delta we also save the total number of coordinates).
  // Currently the database stores only the coordinates and we would need separate field to store the delta coordinates.
  mode: "validation" | "delta";
};
const ValidationProvider: React.FC<React.PropsWithChildren<ValidationProviderProps>> = ({
  id,
  saveAs,
  onClose,
  children,
  initialCoordinates,
  isVisible,
  mode,
}) => {
  const { handleMutationSettled } = useValidationPanel();
  const { isChainValidation, nextValidation } = useChainValidation();
  const queryClient = useQueryClient();

  const [coordinateList, setCoordinateList] = React.useState(initialCoordinates);
  const [imageIsVisible, setImageIsVisible] = React.useState(isVisible);
  const [coordinateIndexToDelete, setCoordinateIndexToDelete] = React.useState<number | null>(null);

  const { jsx: theSnack, setErrorList } = useCustomSnackBar();

  // Simply remove the selected coordinate from the list
  const deleteSelectedCoordinate = React.useCallback(() => {
    if (coordinateIndexToDelete === null) {
      return;
    }
    setCoordinateList((coordinates) =>
      coordinates.filter((_, index) => index !== coordinateIndexToDelete)
    );
    setCoordinateIndexToDelete(null);
  }, [coordinateIndexToDelete, setCoordinateIndexToDelete]);

  const stageRef = React.useRef<StageRef | null>(INITIAL_VALUES.stageRef.current);

  const updateImageMutation = useMutation({
    mutationFn: updateImage,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["list-images"] });
      queryClient.invalidateQueries({ queryKey: ["list-all-images"] });
      queryClient.invalidateQueries({ queryKey: ["detail-image", { id }] });
      queryClient.invalidateQueries({ queryKey: ["validator-device-detail"] });
    },
    onError: (e) => {
      Sentry.captureException(e);
      setErrorList((pre) => [...pre, "Failed to update image details. Please try again."]);
    },
    onSettled: () => {
      handleMutationSettled();
    },
  });

  const uploadMutation = useMutation({
    mutationFn: async (encoded: string) => {
      const blob = convertBase64ToBlob(encoded);
      await uploadImage({ id, type: saveAs, blob });
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["list-images"] });
      queryClient.invalidateQueries({ queryKey: ["detail-image", { id }] });
      queryClient.invalidateQueries({ queryKey: ["download-image", { id, type: saveAs }] });

      // If chain validation is in progresss don't close
      isChainValidation ? nextValidation() : onClose();
    },
    onError: (e) => {
      Sentry.captureException(e);
      setErrorList((pre) => [...pre, "Failed to upload validated image. Please try again."]);
    },
    onSettled: () => {
      handleMutationSettled();
    },
  });

  // TODO: at some point we should merge upload coordinates endpoint with update image endpoint.
  const uploadCoordinatesMutation = useMutation({
    mutationFn: (body: UpdateImageCoordinateParams) => {
      return uploadImageCoordinates(body);
    },
    onError: (e) => {
      Sentry.captureException(e);
      setErrorList((pre) => [...pre, "Failed to upload validated coordinates. Please try again."]);
    },
  });

  const {
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    clearAnnotations,
    annotations,
    newAnnotation,
  } = useAnnotations({
    coordinateList,
    setCoordinateList,
  });

  const onSave = React.useCallback(() => {
    const stage = stageRef.current?.clone() as StageRef | null;
    if (!stage) throw new Error("Stage not exists, can't save");

    const { x, y } = stage.scale()!;

    stage.width(stage.width() / x);
    stage.height(stage.height() / y);
    stage.scale({ x: 1, y: 1 });

    const encoded = stage.toDataURL({
      mimeType: "image/jpeg",
      quality: 0.95,
    });
    if (!encoded) {
      throw new Error("Encoded dataURL is undefined, can't save");
    }

    // Upload image (image with bounding boxes baked in)
    uploadMutation.mutate(encoded);

    if (mode === "validation") {
      // save coordinates
      uploadCoordinatesMutation.mutate({
        id,
        body: {
          coordinates: coordinateList,
        },
      });

      // update detection count with number of pests and mark image as validated
      updateImageMutation.mutate({
        id,
        body: { is_validated: true, detection_count: coordinateList.length },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, saveAs, coordinateList]);

  // Change the is_visible field for the image
  const onChangeVisibility = React.useCallback(() => {
    setImageIsVisible((prevVisibility) => {
      const newVisibility = !prevVisibility;

      updateImageMutation.mutate({
        id,
        body: { is_visible: newVisibility },
      });

      return newVisibility;
    });
  }, [id, updateImageMutation]);

  const value = React.useMemo(
    () => ({
      stageRef,
      annotations,
      newAnnotation,
      handleMouseDown,
      handleMouseMove,
      handleMouseUp,
      onClear: clearAnnotations,
      onSave,
      isSaving:
        uploadMutation.isLoading ||
        updateImageMutation.isLoading ||
        uploadCoordinatesMutation.isLoading,
      onClose,
      onChangeVisibility,
      setCoordinateList,
      coordinateIndexToDelete,
      setCoordinateIndexToDelete,
      deleteSelectedCoordinate,
      isVisible: imageIsVisible,
    }),
    [
      annotations,
      newAnnotation,
      handleMouseDown,
      handleMouseMove,
      handleMouseUp,
      clearAnnotations,
      onSave,
      uploadMutation.isLoading,
      updateImageMutation.isLoading,
      uploadCoordinatesMutation.isLoading,
      onClose,
      onChangeVisibility,
      coordinateIndexToDelete,
      deleteSelectedCoordinate,
      imageIsVisible,
    ]
  );
  return (
    <ValidationContext.Provider value={value}>
      {theSnack}
      {children}
    </ValidationContext.Provider>
  );
};

export default ValidationProvider;

export const useValidation = () => React.useContext(ValidationContext);
