import React from "react";
import { SxProps, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
// import PreferencesDrawer from "./Drawers/PreferencesDrawer";

type AppWrapperProps = {
  mainSxProps?: SxProps;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AppWrapper = React.forwardRef<HTMLDivElement, React.PropsWithChildren<AppWrapperProps>>(
  ({ children = true, mainSxProps = {} }, ref) => {
    const theme = useTheme();

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          backgroundColor: "#F1EEEA"
        }}
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: [2, 2, 3, 3],
            display: "flex",
            flexDirection: "column",
            maxWidth: theme.breakpoints.values.xl,
            width: "100%",
            alignSelf: [undefined, undefined, undefined, undefined, "center"],
            ...mainSxProps,
          }}
          ref={ref}
        >
          {children}
        </Box>
        {/* <PreferencesDrawer /> */}
      </Box>
    );
  }
);

export default AppWrapper;
