import React from 'react';
import { 
  Modal, 
  Box, 
  Typography, 
  TextField, 
  Grid, 
  IconButton, 
  Alert, 
} from '@mui/material';
import { LoadingButton } from "@mui/lab";
import CloseIcon from '@mui/icons-material/Close';
import PasswordAdornment from "components/PasswordAdornment";
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { AxiosError } from 'axios';
import { ValidatorFormData } from '../useValidatorManagement';

interface AddValidatorModalProps {
  open: boolean;
  onClose: () => void;
  register: UseFormRegister<ValidatorFormData>;
  errors: FieldErrors<ValidatorFormData>;
  showPassword: boolean;
  isLoading: boolean;
  handleClickShowPassword: () => void;
  handleSaveAndClose: () => void;
  handleAssignDevices: () => void;
  registerError: AxiosError | null;
}

const AddValidatorModal: React.FC<AddValidatorModalProps> = ({ 
  open, 
  onClose, 
  register, 
  errors, 
  showPassword, 
  isLoading, 
  handleClickShowPassword, 
  handleSaveAndClose, 
  handleAssignDevices,
  registerError
}) => {

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="add-validator-modal"
      disableEscapeKeyDown
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
      }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
          Add New Validator
        </Typography>
        
        {registerError !== null && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {(() => {
              switch (registerError.response?.status) {
                case 400:
                  return "User already exists";
                case 500:
                  return "Server error, please try again later";
                default:
                  return "Registration error";
              }
            })()}
          </Alert>
        )}

        <form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Username"
                {...register("username")}
                error={!!errors.username}
                helperText={errors.username?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="First Name"
                {...register("firstName")}
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Last Name"
                {...register("lastName")}
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                type="email"
                {...register("email")}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Password"
                type={showPassword ? "text" : "password"}
                {...register("password")}
                error={!!errors.password}
                helperText={errors.password?.message}
                InputProps={{
                  endAdornment: (
                    <PasswordAdornment
                      showPassword={showPassword}
                      onTogglePasswordVisibility={handleClickShowPassword}
                    />
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container direction="column" spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                variant="outlined"
                onClick={handleSaveAndClose}
                loading={isLoading}
              >
                Save and Close
              </LoadingButton>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                variant="contained"
                onClick={handleAssignDevices}
                loading={isLoading}
              >
                Assign Devices
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default AddValidatorModal;
