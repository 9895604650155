import React from "react";
import { Box } from "@mui/material";
import DeviceTable from "./exportTable";

const DeviceManagement = () => {
  return (
    <Box>
        <DeviceTable />
    </Box>
  );
};

export default DeviceManagement;