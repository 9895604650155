import React from 'react';
import { Alert } from '@mui/material';

interface PendingImagesAlertProps {
  pendingImages: number;
}

const PendingImagesAlert: React.FC<PendingImagesAlertProps> = ({ pendingImages }) => {
  return (
    <Alert
      severity={pendingImages > 0 ? "info" : "success"}
      sx={{
        marginTop: '16px',
        backgroundColor: pendingImages > 0 ? '#FCE1CB' : '#D8F1D3',
        color: pendingImages > 0 ? '#DB721F' : '#428734',
        '& .MuiAlert-icon': {
          color: pendingImages > 0 ? '#DB721F' : '#428734',
        },
        marginBottom: '24px'
      }}
    >
      {pendingImages > 0
        ? `You have ${pendingImages} images waiting for validation!`
        : 'No images waiting for validation.'}
    </Alert>
  );
};

export default PendingImagesAlert;
