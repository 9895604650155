import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import { Modal, Box, IconButton } from "@mui/material";
import ValidatorViewWithId from "../ValidatorWithId";

interface ValidatorModalProps {
  open: boolean;
  onClose: () => void;
  validatorId: string | null;
}

const ValidatorDevicesManageModal: React.FC<ValidatorModalProps> = ({ open, onClose, validatorId }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="validator-modal-title"
      aria-describedby="validator-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        maxHeight: '90vh',
        overflowY: 'auto',
      }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {validatorId && <ValidatorViewWithId id={validatorId} />}
      </Box>
    </Modal>
  );
};

export default ValidatorDevicesManageModal;
