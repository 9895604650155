import React from "react";
import { Typography } from "@mui/material";

interface TypographyHighlitedProps {
  children: React.ReactNode;
  status: "ok" | "warning" | "error"; 
}

export const TypographyHighlited: React.FC<TypographyHighlitedProps> = ({ children, status }) => {
  const styles = {
    ok: {
      backgroundColor: "#D8F1D3",
      color: "#428734",
    },
    warning: {
      backgroundColor: "#FCE1CB",
      color: "#DB721F",
    },
    error: {
      backgroundColor: "#FBC1C1",
      color: "#AB0B0B",
    },
  };

  return (
    <Typography
      variant="caption"
      sx={{
        display: "inline-block",
        padding: "2px 8px",
        borderRadius: "8px",
        width: "fit-content",
        ...styles[status],
      }}
    >
      {children}
    </Typography>
  );
};

export default TypographyHighlited;

