import React, { useMemo } from "react";
import { DialogContent, Box, MenuItem, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Controller, FormProvider, useForm, useFormContext } from "react-hook-form";
import { listPests } from "queries/pests";
import { listCrops } from "queries/crops";
import { listConfigs } from "queries/configs";
import { listFirmwares } from "queries/firmwares";
import { allCommissionStatuses } from "queries/devices/commissionStatus";
import {
  ListDeviceItem,
  UpdateDeviceParams,
  UpdateDevicePartialParams,
  updateDevice,
} from "queries/devices";
import { yupResolver } from "@hookform/resolvers/yup";
import DeviceFields from "./DeviceFields";

import UpdateDeviceSettingsDialogActions, {
  UpdateDeviceSettingsDialogActionsProps,
} from "./Actions";
import { UpdateDeviceSettingsFormValues, schema } from "./types";
import { BatchQueriesResultType } from "./useBatchQueries";

type ComissonSelectType = {
  commissionLabel: string;
};

const ComissonSelect = ({ commissionLabel }: ComissonSelectType) => {
  const { control } = useFormContext<UpdateDeviceSettingsFormValues>();

  const selectData = useMemo(() => {
    return allCommissionStatuses.map((v) => {
      return (
        <MenuItem key={v.value} value={v.value}>
          {v.label}
        </MenuItem>
      );
    });
  }, []);

  return (
    <Controller
      control={control}
      name="target_commission_status"
      render={({ field: { value, onChange, ...field } }) => (
        <TextField
          select
          size="small"
          label="Commission Status"
          {...field}
          defaultValue={commissionLabel}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        >
          {selectData}
        </TextField>
      )}
    />
  );
};

type UpdateDeviceSettingsDialogFormProps = {
  initialValues: UpdateDeviceSettingsFormValues;
  id: string;
  handleSuccess: () => void;
  handleError: (error: Error) => void;
  actionsProps: UpdateDeviceSettingsDialogActionsProps;
  batchQuery: BatchQueriesResultType;
};
const UpdateDeviceSettingsDialogForm: React.FC<UpdateDeviceSettingsDialogFormProps> = ({
  id,
  initialValues,
  handleSuccess,
  handleError,
  actionsProps,
  batchQuery,
}) => {
  const methods = useForm<UpdateDeviceSettingsFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const params = useMemo(() => {
    const f = [
      { name: "Crop", registerName: "target_crop", query: listCrops },
      { name: "Pest", registerName: "target_pest", query: listPests },
      { name: "Config", registerName: "target_config", query: listConfigs },
      { name: "Firmware", registerName: "target_firmware", query: listFirmwares },
      ];
    return f.map((v) => {
      return {
        ...batchQuery.find((bq) => bq.name === v.name)!,
        ...v,
      };
    });
  }, [batchQuery]);

  const commissionLabel = batchQuery.find((vv) => vv.name === "CommissionStatus")?.label;

  const mutation = useMutation<ListDeviceItem, Error, UpdateDeviceParams, unknown>({
    mutationFn: updateDevice,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const onSubmit = React.useCallback(
    (params: UpdateDeviceSettingsFormValues) => {
      if (!id) throw new Error("No device id when submitting");

      const paramsWithoutNull = Object.fromEntries(
        Object.entries(params).filter(([key, value]) => value !== null)
      ) as UpdateDevicePartialParams;

      const lureFields = {
        lure_type: methods.getValues("target_lure_type"),
        lure_days_life_span: methods.getValues("target_lure_days_life_span"),
        lure_last_replaced: methods.getValues("target_lure_last_replaced"),
      };

      const finalParams = { ...paramsWithoutNull, ...lureFields };

      mutation.mutate({ id, params: finalParams });


    },
    [id, mutation, methods]
  );

  return (
    <FormProvider {...methods}>
      <Box noValidate component="form" onSubmit={methods.handleSubmit(onSubmit)}>
        <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 2 }}>
          <DeviceFields params={params} />

          {commissionLabel !== undefined && (
            <ComissonSelect commissionLabel={commissionLabel ?? ""} />
          )}

          <Controller
            name="target_lure_type"
            control={methods.control}
            render={({ field }: { field: any }) => (
              <TextField
          {...field}
          size="small"
          label="Lure Type"
          variant="outlined"
              />
            )}
          />
          <Controller
            name="target_lure_days_life_span"
            control={methods.control}
            render={({ field }: { field: any }) => (
              <TextField
          {...field}
          size="small"
          label="Lure Lifespan"
          variant="outlined"
              />
            )}
          />
          <Controller
            name="target_lure_last_replaced"
            control={methods.control}
            render={({ field }: { field: any }) => (
              <TextField
          {...field}
          size="small"
          label="Lure Last Replaced"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={field.value ? new Date(field.value * 1000).toISOString().split('T')[0] : ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const date = new Date(e.target.value);
            field.onChange(date.getTime() / 1000);
          }}
              />
            )}
          />
        </DialogContent>

        <UpdateDeviceSettingsDialogActions isSaveLoading={mutation.isLoading} {...actionsProps} />
      </Box>
    </FormProvider>
  );
};

export default UpdateDeviceSettingsDialogForm;
