import React from "react";
import { Alert, Box, Button } from "@mui/material";
import { DeviceDetailedStatus, DeviceStatus, statusToReadable } from "utils/device-statuses";

interface StatusChangeApprovalAlertProps {
  status: DeviceDetailedStatus;
  lastNotApprovedStatus: DeviceDetailedStatus;
  statusNeedsApproval: boolean;
  onApproveStatus: () => void;
}

const StatusChangeApprovalAlert: React.FC<StatusChangeApprovalAlertProps> = ({
  status,
  lastNotApprovedStatus,
  statusNeedsApproval,
  onApproveStatus,
}) => {
  return (
    <Alert
      severity="info"
      sx={{
        marginTop: "32px",
        backgroundColor: "#FCE1CB",
        color: "#DB721F",
        "& .MuiAlert-icon": {
          color: "#DB721F",
          alignSelf: "center",
        },
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box>
          Pending approval of status change to <b>{statusToReadable(lastNotApprovedStatus)}</b>.
          Currently visible status is <b>{statusToReadable(status)}</b>.
        </Box>
        <Button
          variant="contained"
          size="small"
          sx={{
            fontSize: "12px",
            width: "120px",
            marginLeft: "16px",
          }}
          onClick={() => onApproveStatus()}
        >
          Approve
        </Button>
      </Box>
    </Alert>
  );
};

export default StatusChangeApprovalAlert;
