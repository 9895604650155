import React from "react";
import useAvgDetectionCountDeltas, {
  UseAvgDetectionCountDeltasOptions,
} from "hooks/useAvgDetectionCountDeltas";
import useSumDetectionCountDeltas from "hooks/useSumDetectionCountDeltas";
import {
  useDetectionCountsByDevices,
  useDetectionCountsByOrchards,
} from "hooks/useDetectionCountsByIDs";
import { Aggregations, Frequency } from "utils/query";
import ChartWithRQ from "./ChartWithRQ";
import { ChartProps } from "./Chart";

export const DC_CHART_CONFIG = {
  dataKey: "detectionCountDelta",
  stroke: "#F4B71D",
  connectNulls: true,
  strokeWidth: 3,
  fill: "#F4B71D99",
} as const;

const DC_CHART_CONFIG_ARR = [DC_CHART_CONFIG];

export type DetectionCountDeltaChartProps = UseAvgDetectionCountDeltasOptions &
  Pick<ChartProps<"detectionCountDelta">, "chartConfig">;

export const DetectionCountDeltaSummedChart: React.FC<
  DetectionCountDeltaChartProps & { frequency: string; orchard?: string }
> = ({ chartConfig = DC_CHART_CONFIG_ARR, ...props }) => {
  const { data, error } = useSumDetectionCountDeltas(props);
  return <ChartWithRQ data={data} error={error} chartConfig={chartConfig} />;
};

const DetectionCountDeltaChart: React.FC<
  DetectionCountDeltaChartProps & { frequency: string; orchard?: string }
> = ({ chartConfig = DC_CHART_CONFIG_ARR, ...props }) => {
  const { data, error } = useAvgDetectionCountDeltas(props);
  return <ChartWithRQ data={data} error={error} chartConfig={chartConfig} />;
};
export default DetectionCountDeltaChart;

export const DetectionCountsByOrchardsChart: React.FC<
  DetectionCountDeltaChartProps & {
    frequency: Frequency;
    aggregations: Aggregations;
    orchardID: string;
  }
> = ({ chartConfig = DC_CHART_CONFIG_ARR, ...props }) => {
  const { data, error } = useDetectionCountsByOrchards(props);
  return <ChartWithRQ data={data} error={error} chartConfig={chartConfig} />;
};

export const DetectionCountsByDevicesChart: React.FC<
  DetectionCountDeltaChartProps & {
    frequency: Frequency;
    aggregations: Aggregations;
    deviceID: string;
  }
> = ({ chartConfig = DC_CHART_CONFIG_ARR, ...props }) => {
  const { data, error } = useDetectionCountsByDevices(props);
  return <ChartWithRQ data={data} error={error} chartConfig={chartConfig} />;
};
