import { useState, useEffect } from 'react';
import { useQueryClient } from "@tanstack/react-query";
import { useAuth } from "context/AuthProvider";
import { useAbout } from "context/AboutProvider";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation } from 'react-router-dom';
import { USER_TYPE } from 'queries/users';
import { getMenuItems, MenuItem } from './constants/menuItem';

const useVerticalMenuBar = (userType: USER_TYPE) => {
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState<string>('');
  const [selectedSubItem, setSelectedSubItem] = useState<string | null>(null);
  const [openSubMenu, setOpenSubMenu] = useState<string | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const queryClient = useQueryClient();
  const { logout } = useAuth();
  const { open: openAbout } = useAbout();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const currentPath = location.pathname;
    setSelectedSubItem(null);
    
    const findSelectedItem = (items: MenuItem[]): void => {
      items.forEach(item => {
        if (item.path === currentPath) {
          setSelectedItem(item.text);
        }

        if (item.subItems) {
          const matchingSubItem = item.subItems.find(subItem => subItem.path === currentPath);
          if (matchingSubItem) {
            setSelectedItem(item.text);
            setSelectedSubItem(matchingSubItem.text);
          }
        }
      });
    };
    findSelectedItem(getMenuItems(userType));
  }, [location.pathname, userType]);

  const handleSubMenuToggle = (text: string) => {
    setOpenSubMenu(openSubMenu === text ? null : text);
  };

  const handleMouseEnter = () => {
    if (selectedItem && selectedSubItem) {
      setOpenSubMenu(selectedItem);
    }
  };

  const handleMouseLeave = () => {
    setOpenSubMenu(null);
  };

  const handleItemClick = (item: MenuItem) => {
    if (isSmallScreen) {
      setIsMenuOpen(false);
    }
    
    if (item.onClick) {
      item.onClick();
    } else if (item.text === 'About') {
      openAbout();
    } else if (item.text === 'Logout') {
      queryClient.removeQueries();
      logout();
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return {
    openSubMenu,
    isMenuOpen,
    isSmallScreen,
    handleSubMenuToggle,
    handleItemClick,
    toggleMenu,
    handleMouseLeave,
    handleMouseEnter,
    selectedItem,
    selectedSubItem,
  };
};

export default useVerticalMenuBar;
