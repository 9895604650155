/* eslint-disable react/no-unused-prop-types */
import { ListPestItem } from "queries/pests";
import React from "react";
import { Box, Button, Card, CardContent, Stack, Tooltip, Typography } from "@mui/material";
import BatteryVoltageChip from "components/deviceChips/BatteryVoltageChip";
import SolarCellVoltageChip from "components/deviceChips/SolarCellVoltageChip";
import HumidityChip from "components/deviceChips/HumidityChip";
import * as timeago from "timeago.js";
import TemperatureChip from "components/deviceChips/TemperatureChip";
import PestChip from "components/deviceChips/PestChip";
import CropChip from "components/deviceChips/CropChip";
import CommissionStatusChip from "components/deviceChips/CommissionStatusChip";
import DetectionCountCatchChip from "components/deviceChips/DetectionCountCatchChip";
import { DEVICE_TYPE, ListDeviceItem } from "queries/devices";
import { ListCropItem } from "queries/crops";
import { ListMeasurementItem } from "queries/devices/measurements";
import { ListNetworkDiagnosticItem } from "queries/devices/networkDiagnostics";
import { ListCommissionStatusItem } from "queries/devices/commissionStatus";
import { Link } from "react-router-dom";
import { ListFirmwareItem, boardMajorMinorPatch } from "queries/firmwares";
import { ListGeolocationItem } from "queries/devices/geolocations";
import { ListOneSevenDayDcDItem } from "queries/devices/averageDetectionCounts";
import { useAuth } from "context/AuthProvider";
import ROUTES from "routes";
import { ListConfigItem } from "queries/configs";
import StatusIndicator from "components/StatusIndicator";
import RssiChip from "components/deviceChips/RssiChip";
import FirmwareChip from "components/deviceChips/FirmwareChip";
import ConfigChip from "components/deviceChips/ConfigChip";
import useImeiLabel from "hooks/useImeiLabel";
import AreaChip from "components/deviceChips/AreaChip";
import TimezoneChip from "components/deviceChips/TimezoneChip";
import LastSyncChip from "components/deviceChips/LastSyncChip";
import CorporateChip from "components/deviceChips/CorporateChip";
import AdminApprovalChip from "components/deviceChips/AdminApprovalChip";
import TrapFixingInstructionsChip from "components/deviceChips/TrapFixingInstructionsChip";
import { getDeviceDetailedStatus, getDeviceGeneralStatus } from "utils/device-statuses";
import DeviceTypeImage from "./DeviceTypeImage";

export type DeviceCardProps = Omit<
  ListDeviceItem,
  | "current_pest"
  | "last_measurement"
  | "current_crop"
  | "last_network_diagnostic"
  | "current_commission_status"
  | "current_firmware"
  | "current_config"
  | "last_geo_point"
> & {
  current_crop: ListCropItem | null;
  current_pest: ListPestItem | null;
  last_measurement: ListMeasurementItem | null;
  last_network_diagnostic: ListNetworkDiagnosticItem | null;
  current_commission_status: ListCommissionStatusItem | null;
  current_firmware: ListFirmwareItem | null;
  current_config: ListConfigItem | null;
  last_geo_point: ListGeolocationItem | null;
  one_and_seven_day_dc_dcd?: ListOneSevenDayDcDItem | null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DeviceCard = React.forwardRef<HTMLDivElement, DeviceCardProps>(
  (
    {
      id,
      name,
      type,
      smapp_id,
      imei,
      mac,
      iccid,
      last_measurement,
      current_firmware,
      last_network_diagnostic,
      current_commission_status,
      current_crop,
      current_pest,
      country,
      city,
      timezone,
      current_config,
      corporations,
      one_and_seven_day_dc_dcd,
      last_status,
      status,
      status_general,
    },
    ref
  ) => {
    const imeiLabel = useImeiLabel();
    const { isAdmin } = useAuth();
    const isValidMac = mac !== null;
    const {
      last_validation: lastValidation = null,
      last = { dc: 0, dcd: 0 },
      week = { dc: 0, dcd: 0 },
    } = one_and_seven_day_dc_dcd ?? {};

    return (
      <Card
        sx={{
          boxShadow: "none",
          border: "1px solid lightgrey",
          boxSizing: "border-box",
          transition: "background-color 0.2s ease-in-out",
          "&:hover": {
            borderColor: "darkgray",
            backgroundColor: "#fafafa",
          },
        }}
        ref={ref}
      >
        <CardContent>
          <Box sx={{ mb: 2, display: "flex" }}>
            <Stack direction="row" spacing={1} sx={{ flex: 1 }}>
              <StatusIndicator status={getDeviceGeneralStatus({ status_general, last_status })} />
              <DeviceTypeImage type={type} />

              <Stack direction="column">
                <Tooltip title={name ? "NAME" : "SMAPP_ID"} arrow>
                  <Typography color="text.primary" fontSize={16} fontWeight={600}>
                    {name ? (
                      <>
                        {name} <Typography variant="caption">{smapp_id}</Typography>
                      </>
                    ) : (
                      smapp_id
                    )}
                  </Typography>
                </Tooltip>
                <Tooltip title={isValidMac ? "MAC" : imeiLabel} arrow>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    {isValidMac ? mac : imei}
                  </Typography>
                </Tooltip>
                {isAdmin && (
                  <Tooltip title="ICCID" arrow>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                      {iccid}
                    </Typography>
                  </Tooltip>
                )}
              </Stack>
            </Stack>
            <Link to={`${ROUTES.DEVICES}/${id}`}>
              <Button variant="outlined" size="small">
                Open
              </Button>
            </Link>
          </Box>
          <Box sx={{ display: "flex", flex: 1, flexWrap: "wrap", gap: 1 }}>
            <BatteryVoltageChip value={last_measurement?.battery_voltage} />
            {type === DEVICE_TYPE.Premium && (
              <SolarCellVoltageChip value={last_measurement?.solar_cell_voltage} />
            )}
            {type === DEVICE_TYPE.Premium && <HumidityChip value={last_measurement?.humidity} />}
            {type === DEVICE_TYPE.Premium && (
              <TemperatureChip value={last_measurement?.temperature} />
            )}
            <CropChip value={current_crop?.name} />
            <PestChip value={current_pest?.name} />
            {isAdmin && (
              <CommissionStatusChip value={current_commission_status?.commission_status} />
            )}
            <RssiChip value={last_network_diagnostic?.rssi} />
            {isAdmin && <FirmwareChip value={boardMajorMinorPatch(current_firmware)} />}
            {isAdmin && <ConfigChip value={current_config} />}
            <AreaChip country={country} city={city} />
            {isAdmin && <TimezoneChip value={timezone} />}
            {isAdmin && <LastSyncChip value={last_measurement?.created_at} />}
            {isAdmin && corporations && <CorporateChip value={corporations.join(",")} />}
            <DetectionCountCatchChip title="catch in 7 days" value={week} />
            <DetectionCountCatchChip
              prefixTitle="Last catch: "
              title={`${lastValidation ? "| " : ""} ${
                lastValidation ? timeago.format(lastValidation * 1000) : ""
              }`}
              value={last}
            />
            <TrapFixingInstructionsChip
              detailedStatus={getDeviceDetailedStatus({ status, last_status })}
            />
            <AdminApprovalChip lastStatus={last_status} />
          </Box>
        </CardContent>
      </Card>
    );
  }
);

export default DeviceCard;
