import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getValidatorDeviceById } from "queries/validators";

export const useDeviceInfoPanel = (deviceId: string) => {
  const queryClient = useQueryClient();
  const deviceDetailQuery = useQuery({
    queryKey: ["validator-device-detail", deviceId],
    queryFn: async () => getValidatorDeviceById(deviceId),
    onError: (err) => {
      console.error("Error fetching validator device:", err);
    },
  });

  const reloadDeviceDetails = () => {
    queryClient.invalidateQueries({ queryKey: ["validator-device-detail"] });
  };

  return {
    deviceDetail: deviceDetailQuery.data,
    loading: deviceDetailQuery.isLoading,
    error: deviceDetailQuery.error,
    reloadDeviceDetails,
  };
};

export default useDeviceInfoPanel;
