import { useState, useEffect } from "react";
import { listValidatorDevices } from "queries/validators";
import { ListValidatorDeviceParams, ValidatorDevice } from "types/validators";

export const useValidatorDevices = () => {
  const [devices, setDevices] = useState<ValidatorDevice[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [queryParams, setQueryParams] = useState<ListValidatorDeviceParams>({});

  const fetchDevices = async (page: number, queryParams: ListValidatorDeviceParams) => {
    setLoading(true);

    try {
      const params: ListValidatorDeviceParams = {
        ...queryParams,
        ...{ page },
      };

      const response = await listValidatorDevices(params);
      setPage(page + 1);

      if (!response.meta.next) {
        setHasMore(false);
      }

      setDevices((prevDevices) => {
        const newDevices = response.data.filter(
          (newDevice) => !prevDevices.some((device) => device.id === newDevice.id)
        );
        return [...prevDevices, ...newDevices];
      });
    } catch (err) {
      setError("Failed to load devices");
    } finally {
      setLoading(false);
    }
  };

  const fetchMore = () => {
    if (hasMore && !isLoading) {
      fetchDevices(page, queryParams);
    }
  };

  const changeQueryParams = (params: ListValidatorDeviceParams) => {
    setDevices([]);
    setQueryParams(params);
  };

  useEffect(() => {
    fetchDevices(1, queryParams);
  }, [queryParams]);

  return { devices, isLoading, error, hasMore, fetchMore, changeQueryParams };
};

export default useValidatorDevices;
