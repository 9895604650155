import React from "react";
import { Modal, Box } from "@mui/material";
import { IMAGE_TYPES } from "queries/devices/images";
import { ImageView } from "components/Image/ImageView";

interface FullImageModalProps {
  open: boolean;
  onClose: () => void;
  imageUrl: string;
}

export const FullImageModal: React.FC<FullImageModalProps> = ({ open, onClose, imageUrl }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="full-image-modal"
      aria-describedby="full-size-pest-image"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "90vw",
          maxHeight: "90vh",
          outline: "none",
        }}
      >
        <img
          src={imageUrl}
          alt="Full size pest"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
      </Box>
    </Modal>
  );
};

interface FullImageModalV2Props {
  open: boolean;
  onClose: () => void;
  imageId: string;
}

export const FullImageModalV2: React.FC<FullImageModalV2Props> = ({ open, onClose, imageId }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="full-image-modal"
      aria-describedby="full-size-pest-image"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90vw",
          height: "90vh",
          outline: "none",
        }}
      >
        <ImageView id={imageId} type={IMAGE_TYPES.raw} />
      </Box>
    </Modal>
  );
};

export default FullImageModal;
