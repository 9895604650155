import React, { useState } from "react";
import * as Yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { TextField, Container, Box, Typography, Alert, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "context/AuthProvider";
import { AccessTokenParams } from "queries/auth";
import ROUTES from "routes";
import { Link } from "react-router-dom";

import { ReactComponent as LogoHBlack } from "icons/logo-h-black.svg";
import PasswordAdornment from "components/PasswordAdornment";

const schema = Yup.object({
  username: Yup.string().required(),
  // .email().required(),
  password: Yup.string().required(),
});

const LoginForm: React.FC = () => {
  const { login, isLoggingIn, authError } = useAuth();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AccessTokenParams>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<AccessTokenParams> = (data) => login(data);

  return (
    <Container
      component="form"
      sx={{
        width: "33ch",
        background: "white",
        p: 5,
        borderRadius: 8,
        boxShadow: "2",
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box sx={{ width: "80%", margin: "0 auto 20px auto" }}>
        <LogoHBlack />
      </Box>
      {!process.env.REACT_APP_ALLOW_ALL && (
        <Typography variant="overline">Only admin users</Typography>
      )}
      {authError !== null && authError.response?.status === 401 && (
        <Alert severity="error">Wrong username or password !</Alert>
      )}
      <TextField
        label="E-mail"
        error={!!errors.username}
        helperText={errors.username?.message}
        placeholder="name@company.com"
        required
        fullWidth
        size="small"
        margin="normal"
        {...register("username")}
      />
       <TextField
        type={showPassword ? "text" : "password"}
        placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
        label="Password"
        error={!!errors.password}
        helperText={errors.password?.message}
        required
        fullWidth
        size="small"
        margin="normal"
        {...register("password")}
        InputProps={{
          endAdornment: (
            <PasswordAdornment
            showPassword={showPassword}
            onTogglePasswordVisibility={handleClickShowPassword}
          />
          ),
        }}
      />
      <LoadingButton
        loading={isLoggingIn}
        variant="contained"
        fullWidth
        sx={{ mt: 2 }}
        type="submit"
      >
        Log in
      </LoadingButton>
      <Link to={`${ROUTES.REGISTER}`}>
        <Button
          variant="outlined"
          fullWidth
          sx={{ mt: 2 }}
        >
          Register
        </Button>
      </Link>
    </Container>
  );
};

export default LoginForm;
