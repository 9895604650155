import React from "react";
import { TableRow, TableCell, IconButton, Button, Checkbox, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

interface DeviceDetailsRowItemProps {
    smappId: string;
    corporation: string;
    pestType: string;
    isAssigned: boolean;
    isSelected: boolean;
    handleAssignClick: () => void;
    handleUnassignClick: () => void;
    setIsSelected: (isSelected: boolean) => void;
}

const DeviceDetailsRowItem: React.FC<DeviceDetailsRowItemProps> = ({
    smappId,
    corporation,
    pestType,
    isAssigned,
    isSelected,
    handleAssignClick,
    handleUnassignClick,
    setIsSelected
}) => {
    const handleRowClick = (event: React.MouseEvent) => {
        // Prevent row click when clicking on the assign/unassign button
        if (event.target instanceof HTMLButtonElement || event.target instanceof SVGElement) {
            return;
        }
        setIsSelected(!isSelected);
    };

    return (
        <TableRow
            onClick={handleRowClick}
            sx={{
                backgroundColor: isSelected ? '#9B9999' : '#D6D4D4',
                '& td:first-of-type': {
                    borderTopLeftRadius: '18px',
                    borderBottomLeftRadius: '18px',
                },
                '& td:last-child': {
                    borderTopRightRadius: '18px',
                    borderBottomRightRadius: '18px',
                },
                cursor: 'pointer', 
            }}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    checked={isSelected}
                    onChange={() => setIsSelected(!isSelected)}
                    onClick={(event) => event.stopPropagation()} 
                />
            </TableCell>
            <TableCell>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{smappId.toUpperCase()}</Typography>
                <Typography variant="body1">{corporation}</Typography>
                <Typography variant="body1">{pestType}</Typography>
            </TableCell>
            <TableCell align="right">
                {isAssigned ? (
                    <IconButton onClick={handleUnassignClick} size="small">
                        <CloseIcon />
                    </IconButton>
                ) : (
                    <Button
                        variant="contained"
                        size="small"
                        onClick={handleAssignClick}
                    >
                        ASSIGN
                    </Button>
                )}
            </TableCell>
        </TableRow>
    );
};

export default DeviceDetailsRowItem;
