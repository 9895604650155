import React from "react";
import { Route, Routes } from "react-router-dom";

import ProtectedRoute from "components/ProtectedRoute";
import { useAuth } from "context/AuthProvider";
import { USER_TYPE } from "queries/users";
import ValidatorMain from "./main";
import ValidatorDeviceDetail from "./device";

const ValidatorDeviceList: React.FC = () => {
  const { isAdmin, userType } = useAuth();

  return (
    <Routes>
      <Route
        index
        element={
          <ProtectedRoute>
            {(isAdmin || userType === USER_TYPE.VALIDATOR) && <ValidatorMain />}
          </ProtectedRoute>
        }
      />

      <Route
        path=":deviceId"
        element={
          <ProtectedRoute>
            {(isAdmin || userType === USER_TYPE.VALIDATOR) && <ValidatorDeviceDetail />}
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default ValidatorDeviceList;
