import { useQuery } from "@tanstack/react-query";
import { listAllImages, ListImageItem, ListImagesParams } from "queries/devices/images";
import { useCallback, useState } from "react";

export const useValidatorValidation = (deviceId: string) => {
  const [validatingImages, setValidatingImages] = useState<ListImageItem[]>([]);
  const [validationModelOpen, setValidationModelOpen] = useState(false);

  const notValidatedImagesParam: ListImagesParams = {
    page_size: 100,
    device__id: deviceId,
    ordering: "created_at",
    is_validated: false,
    is_visible: true,
  };
  const notValidatedImagesQuery = useQuery({
    queryKey: ["list-all-images"],
    queryFn: async () => (await listAllImages(notValidatedImagesParam)).data,
    onError: (e) => {
      console.error("Failed to fetch images: ", e);
    },
  });

  const handleFullPictureValidation = () => {
    setValidatingImages(notValidatedImagesQuery.data ?? []);
    setValidationModelOpen(true);
  };

  const handleStartValidation = useCallback((image: ListImageItem) => {
    setValidatingImages([image]);
    setValidationModelOpen(true);
  }, []);

  const handleStopValidation = useCallback(() => {
    setValidationModelOpen(false);
  }, []);

  return {
    validatingImages,
    validationModelOpen,
    listNotValidatedImagesLoading: notValidatedImagesQuery.isLoading,
    listNotValidatedImagesCount: notValidatedImagesQuery.data?.length ?? 0,
    handleStartValidation,
    handleStopValidation,
    handleFullPictureValidation,
  };
};

export default useValidatorValidation;
