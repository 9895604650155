import React from "react";

import AppWrapper from "components/AppWrapper";
import { Navigate, useParams } from "react-router-dom";
import { isNil } from "lodash";
import ROUTES from "routes";
import { isUUID } from "utils/uuid";
import ValidatorDeviceDetailMain from "./ValidatorDeviceDetailMain";

const ValidatorDeviceDetail: React.FC = () => {
  const { deviceId } = useParams();

  if (isNil(deviceId)) return <Navigate to={ROUTES.DEVICES} replace />;
  if (!isUUID(deviceId)) return <Navigate to="/not-found" replace />;

  return (
    <AppWrapper mainSxProps={{ width: "100%", maxWidth: "100%" }}>
      <ValidatorDeviceDetailMain id={deviceId} />
    </AppWrapper>
  );
};

export default ValidatorDeviceDetail;
