import React from "react";
import { Box } from "@mui/material";
import LoginForm from "./LoginForm";

const LoginMain: React.FC = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#F1EEEA"
      }}
    >
      <LoginForm />
    </Box>
  );
};

export default LoginMain;
