import React from "react";
import { Box } from "@mui/material";
import { VisibilityOffOutlined } from "@mui/icons-material";

const VisibilityIssueImageOverlay: React.FC = () => {
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1,
        }}
      />
      <VisibilityOffOutlined
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "#F4B71D",
          zIndex: 3,
          fontSize: "32px",
          backgroundColor: "black",
          borderRadius: "50%",
          padding: "4px",
        }}
      />
    </>
  );
};

export default VisibilityIssueImageOverlay;
