import React from "react";
import { Box, Typography } from "@mui/material";

interface ValidatedImageOverlayProps {
  catchCount: number | null;
  catchCountDelta: number | null;
}

const ValidatedImageOverlay: React.FC<ValidatedImageOverlayProps> = ({
  catchCount,
  catchCountDelta,
}) => {
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px",
          zIndex: 2,
        }}
      >
        <Typography
          sx={{
            backgroundColor: "#F4B71D",
            padding: "16px",
            borderRadius: "50%",
            fontSize: "16px",
            width: "24px",
            height: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {catchCount}
        </Typography>
        <Typography
          sx={{
            backgroundColor: "#F4B71D",
            padding: "16px",
            borderRadius: "50%",
            fontSize: "16px",
            width: "24px",
            height: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          +{catchCountDelta}
        </Typography>
      </Box>
    </>
  );
};

export default ValidatedImageOverlay;
