import React, { useState, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  Button,
  Box,
  TextField,
  CircularProgress,
} from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import DeviceDetailsRowItem from './DeviceDetailsRowItem';
import { Device } from '../useValidatorWithId';

interface DeviceDetailsTableProps {
  isAssignedView: boolean;
  fetchDevices: (page: number, search: string) => Promise<{ devices: Device[], nextPage: number | null }>;
  updateAssignedValidatorForDevice: (deviceIds: string[], assign: boolean) => Promise<void>;
}

const DeviceDetailsTable: React.FC<DeviceDetailsTableProps> = ({
  isAssignedView,
  fetchDevices,
  updateAssignedValidatorForDevice
}) => {
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(
    [isAssignedView ? 'assignedDevices' : 'devices', isAssignedView, searchTerm],
    ({ pageParam = 1 }) => fetchDevices(pageParam, searchTerm),
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
    }
  );

  const devices = data?.pages.flatMap(page => page.devices) || [];

  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight * 1.5 && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  const handleSelectDevice = (deviceId: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectedDevices([...selectedDevices, deviceId]);
    } else {
      setSelectedDevices(selectedDevices.filter((id) => id !== deviceId));
    }
  };

  const handleDeselectAll = () => {
    setSelectedDevices([]);
  };

  const handleAssignSelected = (singleDeviceId?: string) => {
    updateAssignedValidatorForDevice(singleDeviceId ? [singleDeviceId] : selectedDevices, true);
    setSelectedDevices([]);
  };

  const handleUnassignSelected = (singleDeviceId?: string) => {
    updateAssignedValidatorForDevice(singleDeviceId ? [singleDeviceId] : selectedDevices, false);
    setSelectedDevices([]);
  };

  const handleRemoveAll = () => {
    updateAssignedValidatorForDevice(devices.map((device) => device.id), false);
  };

  return (
    <Box 
      sx={{ 
        border: '1px solid #F4B71D', 
        borderRadius: '10px', 
        padding: '20px', 
        height: '70vh', 
        display: 'flex', 
        flexDirection: 'column'
      }}
    >
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <TextField
          size="small"
          placeholder="Search devices..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ width: '300px' }}
        />
        {isAssignedView && (
          <Button variant="contained" size="small" onClick={handleRemoveAll}>
            Remove All
          </Button>
        )}
      </Box>
      <TableContainer 
        sx={{ 
          flexGrow: 1,
          overflow: 'auto',
          mb: 2
        }}
        onScroll={handleScroll}
      >
        <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}>
          <TableBody>
            {devices.map((device) => (
              <DeviceDetailsRowItem
                key={device.id}
                smappId={device.smappId}
                corporation={device.corporation}
                pestType={device.pestType}
                isAssigned={device.isAssigned}
                isSelected={selectedDevices.includes(device.id)}
                handleAssignClick={() => handleAssignSelected(device.id)}
                handleUnassignClick={() => handleUnassignSelected(device.id)}
                setIsSelected={(isSelected) => handleSelectDevice(device.id, isSelected)}
              />
            ))}
          </TableBody>
        </Table>
        {(isLoading || isFetchingNextPage) && (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
            <CircularProgress />
          </Box>
        )}
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button variant="outlined" onClick={handleDeselectAll} sx={{ mr: 1 }}>
          Deselect All
        </Button>
        <Button
          variant="contained"
          onClick={isAssignedView ? 
            () => handleUnassignSelected() : 
            () => handleAssignSelected()
          }
        >
          {isAssignedView ? 'Remove Selected' : 'Assign Selected'}
        </Button>
      </Box>
    </Box>
  );
};

export default DeviceDetailsTable;
