import React from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface PasswordAdornmentProps {
  showPassword: boolean;
  onTogglePasswordVisibility: () => void;
}

const PasswordAdornment: React.FC<PasswordAdornmentProps> = ({
  showPassword,
  onTogglePasswordVisibility,
}) => {
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={onTogglePasswordVisibility}
        onMouseDown={handleMouseDownPassword}
        edge="end"
        size="small"
      >
        {showPassword ? (
          <Visibility
            sx={{ width: '20px', height: '20px', fontSize: '20px' }}
          />
        ) : (
          <VisibilityOff
            sx={{ width: '20px', height: '20px', fontSize: '20px' }}
          />
        )}
      </IconButton>
    </InputAdornment>
  );
};

export default PasswordAdornment;
