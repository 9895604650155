import { useValidation } from "context/ValidationProvider";
import { Annotation } from "hooks/useAnnotations";
import React, { useState } from "react";
import { Layer, Rect, Stage } from "react-konva";
import URLImage from "../URLImage";

const DeletableRect = ({
  annotation: { x, y, width, height, stroke },
  strokeWidth,
  isSelected,
  onClick,
}: {
  annotation: Annotation;
  strokeWidth: number | undefined;
  isSelected: boolean;
  onClick: () => void;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      {/* Hovered or selected background */}
      {(isHovered || isSelected) && (
        <Rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill={stroke}
          // hover is much more subtle than selected
          opacity={isSelected ? 0.4 : 0.2}
        />
      )}

      <Rect
        x={x}
        y={y}
        width={width}
        height={height}
        stroke={stroke}
        strokeWidth={strokeWidth}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        onClick={onClick}
      />
    </>
  );
};

export type ImageEditorStageProps = {
  data: Blob;
  width: number;
};

const ImageEditorStage: React.FC<ImageEditorStageProps> = ({ data, width }) => {
  const {
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    annotations,
    newAnnotation,
    stageRef,
    coordinateIndexToDelete,
    setCoordinateIndexToDelete,
  } = useValidation();

  // Stroke width is a fixed percentage of image width
  const strokeWidth = width ? width * 0.008 : undefined;

  return (
    <Stage
      ref={stageRef}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      width={width}
      height={300}
      style={{
        cursor: "crosshair",
      }}
    >
      <Layer>
        <URLImage obj={data} />
        {annotations.map((value, index) => {
          return (
            <DeletableRect
              // This is fine as a key as we are only adding and removing the last annotation
              // eslint-disable-next-line react/no-array-index-key
              key={`${value.x}-${value.y}-${value.width}-${value.height}-${index}`}
              annotation={value}
              strokeWidth={strokeWidth}
              isSelected={coordinateIndexToDelete === index}
              onClick={() => {
                setCoordinateIndexToDelete(index);
              }}
            />
          );
        })}

        {/* Simply draw the new annotation (no hover or selection options) */}
        {newAnnotation && (
          <Rect
            x={newAnnotation.x}
            y={newAnnotation.y}
            width={newAnnotation.width}
            height={newAnnotation.height}
            stroke={newAnnotation.stroke}
            strokeWidth={strokeWidth}
          />
        )}
      </Layer>
    </Stage>
  );
};

export default ImageEditorStage;
