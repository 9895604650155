import { KonvaEventObject } from "konva/lib/Node";
import { ImageCoordinates, ImageCoordinateSource } from "queries/devices/images";
import React, { useMemo } from "react";

export type StrokeColor = "red" | "blue" | "green";

// Annotations from AI will be show in different color than annotations from human
export const aiStrokeColor = "blue";
export const humanStrokeColor = "green";

export type Annotation = {
  x: number;
  y: number;
  width: number;
  height: number;
  stroke: StrokeColor;
};

const coordinateSourceToStrokeColor = (type: ImageCoordinateSource): StrokeColor => {
  switch (type) {
    case "human":
      return humanStrokeColor;
    case "ai":
      return aiStrokeColor;
    default:
      return humanStrokeColor;
  }
};

const strokeColorToCoordsSource = (stroke: StrokeColor): ImageCoordinateSource => {
  switch (stroke) {
    case humanStrokeColor:
      return "human";
    case aiStrokeColor:
      return "ai";
    default:
      return "human";
  }
};

const annotationToCoord = (annotation: Annotation): ImageCoordinates[number] => ({
  x: annotation.x,
  y: annotation.y,
  w: annotation.width,
  h: annotation.height,
  source: strokeColorToCoordsSource(annotation.stroke),
});

const coordsToAnnotations = (coords: ImageCoordinates): Annotation[] =>
  coords.map((val) => ({
    x: val.x,
    y: val.y,
    width: val.w,
    height: val.h,
    stroke: coordinateSourceToStrokeColor(val.source),
  }));

type UseAnnotationsOptions = {
  coordinateList: ImageCoordinates;
  setCoordinateList: React.Dispatch<React.SetStateAction<ImageCoordinates>>;
};
const useAnnotations = ({ coordinateList, setCoordinateList }: UseAnnotationsOptions) => {
  const annotations = useMemo(() => coordsToAnnotations(coordinateList), [coordinateList]);

  const [newAnnotation, setNewAnnotation] = React.useState<undefined | Annotation>(undefined);

  const handleMouseDown = React.useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      const vector = e.target.getStage()?.getPointerPosition();
      const scale = e.target.getStage()?.scale();

      if (!newAnnotation && vector && scale) {
        const { x, y } = vector;

        setNewAnnotation({
          x: x / scale.x,
          y: y / scale.y,
          width: 0,
          height: 0,
          stroke: humanStrokeColor,
        });
      }
    },
    [newAnnotation]
  );

  const handleMouseUp = React.useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      const vector = e.target.getStage()?.getPointerPosition();
      const scale = e.target.getStage()?.scale();

      if (newAnnotation && vector && scale) {
        const { x: sx, y: sy } = newAnnotation;
        const { x, y } = vector;

        const annotationToAdd: Annotation = {
          x: sx,
          y: sy,
          width: x / scale.x - sx,
          height: y / scale.y - sy,
          stroke: humanStrokeColor,
        };

        // correct for dragging backwards
        if (annotationToAdd.width < 0) {
          annotationToAdd.width = Math.abs(annotationToAdd.width);
          annotationToAdd.x = sx - annotationToAdd.width;
        }

        // correct for dragging upwards
        if (annotationToAdd.height < 0) {
          annotationToAdd.height = Math.abs(annotationToAdd.height);
          annotationToAdd.y = sy - annotationToAdd.height;
        }

        // Add annotation only if width and height are greater than 0
        if (annotationToAdd.width > 0 && annotationToAdd.height > 0) {
          setCoordinateList([...coordinateList, annotationToCoord(annotationToAdd)]);
        }
        setNewAnnotation(undefined);
      }
    },
    [coordinateList, newAnnotation, setCoordinateList]
  );

  const handleMouseMove = React.useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      const vector = e.target.getStage()?.getPointerPosition();
      const scale = e.target.getStage()?.scale();

      if (newAnnotation && vector && scale) {
        const { x: sx, y: sy } = newAnnotation;
        const { x, y } = vector;

        setNewAnnotation({
          x: sx,
          y: sy,
          width: x / scale.x - sx,
          height: y / scale.y - sy,
          stroke: humanStrokeColor,
        });
      }
    },
    [newAnnotation]
  );

  const clearAnnotations = React.useCallback(() => {
    setCoordinateList([]);
    setNewAnnotation(undefined);
  }, [setCoordinateList]);

  return React.useMemo(
    () => ({
      clearAnnotations,
      handleMouseDown,
      handleMouseMove,
      handleMouseUp,
      annotations,
      newAnnotation,
    }),
    [annotations, clearAnnotations, handleMouseDown, handleMouseMove, handleMouseUp, newAnnotation]
  );
};

export default useAnnotations;
