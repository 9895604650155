import React from "react";
import { Card, Typography, Box } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import ROUTES from "routes";
import TypographyHighlited from "components/TypographyHighlited";
import { useAuth } from "context/AuthProvider";

interface DeviceCardProps {
  smpID: string;
  uuid: string;
  pestName: string;
  unvalidatedImageCount: number;
  deviceStatus: string;
  validatorName?: string;
  isApprovalRequired?: boolean;
}

const ValidatorDeviceCard: React.FC<DeviceCardProps> = ({
  smpID,
  uuid,
  pestName,
  unvalidatedImageCount,
  deviceStatus,
  validatorName,
  isApprovalRequired,
}) => {
  const { isAdmin } = useAuth();

  const navigate = useNavigate();

  const handleCardClick = () => navigate(`${ROUTES.VALIDATOR_DEVICES}/${uuid}`);

  return (
    <Card
      sx={{
        padding: "16px",
        paddingTop: "8px",
        display: "flex",
        flexDirection: "column",
        height: "190px",
        boxShadow: 2,
        border: "1px solid #FFFFFF",
        boxSizing: "border-box",
        transition: "background-color 0.2s ease-in-out",
        borderRadius: "18px",
        position: "relative",
        "&:hover": {
          borderColor: "#F4B71D",
          backgroundColor: "#FDF1D3",
        },
      }}
      onClick={handleCardClick}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1 }}>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "8px", color: "#27382C" }}>
            {smpID}
          </Typography>
          {validatorName && isAdmin && (
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {validatorName.toUpperCase()}
            </Typography>
          )}
          <Typography variant="body2">{pestName}</Typography>
          {isApprovalRequired && isAdmin && (
            <Box sx={{ marginTop: 2 }}>
              <TypographyHighlited status="warning">APPROVE REQUIRED</TypographyHighlited>
            </Box>
          )}
        </Box>
        <Typography variant="h4" sx={{ fontWeight: "bold", color: "#27382C" }}>
          {unvalidatedImageCount}
        </Typography>
      </Box>

      <Box sx={{ marginTop: "auto" }}>
        <TypographyHighlited
          status={deviceStatus === "Active" || deviceStatus === "Working" ? "ok" : "error"}
        >
          {deviceStatus}
        </TypographyHighlited>
      </Box>

      <Box
        sx={{
          position: "absolute",
          top: "50%",
          right: "8px",
          transform: "translateY(-50%)",
        }}
      >
        <ArrowForwardIosIcon sx={{ color: "rgba(244, 183, 29, 0.4)" }} />
      </Box>
    </Card>
  );
};

export default ValidatorDeviceCard;
