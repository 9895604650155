/**
 * This file contains all the validator related querys included the queries getting validator devices.
 */

import axios from "axios";
import ListResponse from "types/listResponse";
import { 
  ValidatorDevice, 
  ListValidatorDeviceParams, 
  Validator, 
  ListValidatorParams 
} from "types/validators";

// Function to list validator devices with optional params
export const listValidatorDevices = async (
  params?: ListValidatorDeviceParams
): Promise<ListResponse<ValidatorDevice>> => {
  const { data } = await axios.get<ListResponse<ValidatorDevice>>("/api/validators/devices/", {
    params,
  });
  return data;
};

// Function to get a single validator device by ID
export const getValidatorDeviceById = async (id: string): Promise<ValidatorDevice> => {
  const { data } = await axios.get<ValidatorDevice>(`/api/validators/devices/${id}`);
  return data;
};

// Function to list validators
export const listValidators = async (params?: ListValidatorParams): Promise<ListResponse<Validator>> => {
  const { data } = await axios.get<ListResponse<Validator>>("/api/validators/", { params });
  return data;
};
