import React from "react";
import { Container } from "@mui/material";
import DeviceManagement from "./devices";


const SmappExportMain = () => {
  return (
    <Container
      className="SmappExportMain"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 5,
        justifyContent: "center",
        width: "100%",
        maxWidth: "100% !important",
        p: 0,
        m: 0,
      }}
    >
      <DeviceManagement />
    </Container>
  );
};

export default SmappExportMain;