import React, { useCallback } from "react";

type ChainValidationContextProps = {
  isChainValidation: boolean;
  currrentImageIndex: number;
  allImageCount: number;
  hasNext: boolean;
  skipValidation: () => void;
  nextValidation: () => void;
};

const INITIAL_VALUES: ChainValidationContextProps = {
  isChainValidation: false,
  currrentImageIndex: 0,
  allImageCount: 0,
  hasNext: false,
  skipValidation: () => {},
  nextValidation: () => {},
};

const ChainValidationContext = React.createContext<ChainValidationContextProps>(INITIAL_VALUES);

export type ChainValidationProviderProps = {
  imageIDsArray: string[];
  imageIndex: number;
  setImageIndex: React.Dispatch<React.SetStateAction<number>>;
  onClose: () => void;
};

const ChainValidationProvider: React.FC<React.PropsWithChildren<ChainValidationProviderProps>> = ({
  imageIDsArray,
  imageIndex,
  setImageIndex,
  onClose,
  children,
}) => {
  const imageIDsArrayLength = imageIDsArray.length;
  const isChainValidation = imageIDsArrayLength > 1;

  const hasNextImage = useCallback(() => {
    return imageIndex + 1 < imageIDsArrayLength;
  }, [imageIndex, imageIDsArrayLength]);

  const skipValidation = useCallback(() => {
    if (hasNextImage()) {
      setImageIndex(imageIndex + 1);
    } else {
      onClose();
    }
  }, [hasNextImage, imageIndex, onClose, setImageIndex]);

  const nextValidation = useCallback(() => {
    skipValidation();
  }, [skipValidation]);

  const value = React.useMemo(
    () => ({
      isChainValidation,
      currrentImageIndex: imageIndex,
      allImageCount: imageIDsArrayLength,
      hasNext: hasNextImage(),
      skipValidation,
      nextValidation,
    }),
    [
      isChainValidation,
      imageIndex,
      imageIDsArrayLength,
      hasNextImage,
      skipValidation,
      nextValidation,
    ]
  );
  return (
    <ChainValidationContext.Provider value={value}>{children}</ChainValidationContext.Provider>
  );
};

export default ChainValidationProvider;

export const useChainValidation = () => React.useContext(ChainValidationContext);
