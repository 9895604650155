import React from "react";
import { Box, Button, IconButton } from "@mui/material";
import { useValidation } from "context/ValidationProvider";
import { LoadingButton } from "@mui/lab";
import Fullscreen from "@mui/icons-material/Fullscreen";
import { FullscreenExit } from "@mui/icons-material";
import { useChainValidation } from "context/ChainValidationProvider";

const ToolbarBottom = ({
  isFullscreen,
  toggleFullscreen,
}: {
  isFullscreen: boolean;
  toggleFullscreen: () => void;
}) => {
  const {
    annotations,
    onClear,
    onSave,
    isSaving,
    coordinateIndexToDelete,
    deleteSelectedCoordinate,
  } = useValidation();

  const { isChainValidation, hasNext, skipValidation } = useChainValidation();

  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <IconButton aria-label="fullscreen" onClick={toggleFullscreen} size="small" color="inherit">
        {isFullscreen ? <Fullscreen /> : <FullscreenExit />}
      </IconButton>

      <Box>
        <Button
          variant="outlined"
          size="small"
          sx={{ m: 1 }}
          onClick={() => {
            deleteSelectedCoordinate();
          }}
          disabled={coordinateIndexToDelete === null}
          aria-disabled={coordinateIndexToDelete === null}
        >
          REMOVE SELECTED
        </Button>
      </Box>

      <Box>
        <Button
          variant="outlined"
          size="small"
          sx={{ m: 1 }}
          onClick={onClear}
          disabled={annotations.length === 0}
        >
          CLEAR ALL
        </Button>
      </Box>

      {isChainValidation && (
        <Box>
          <LoadingButton
            loading={isSaving}
            variant="contained"
            size="small"
            sx={{ m: 1 }}
            onClick={skipValidation}
          >
            SKIP
          </LoadingButton>
        </Box>
      )}

      <Box>
        <LoadingButton
          loading={isSaving}
          variant="contained"
          size="small"
          sx={{ m: 1 }}
          onClick={onSave}
        >
          SAVE AND {isChainValidation && hasNext ? "NEXT" : "EXIT"}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default ToolbarBottom;
