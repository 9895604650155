import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import { FlexLoader } from "components/Loader";
import { LatLng } from "leaflet";
import { isNil, debounce } from "lodash";
import { ControlledDeviceMap } from "pages/map/main/DeviceMap";
import { DetailDeviceItem } from "queries/devices";

type MapPanelProps = { device: DetailDeviceItem };
const MapPanel: React.FC<MapPanelProps> = ({ device }) => {
  const [height, setHeight] = React.useState<number | undefined>(undefined);
  const ref = React.useRef<HTMLDivElement>(null);

  const resize = React.useCallback(() => {
    setHeight(ref.current?.getBoundingClientRect().height);
  }, []);

  const removeHeight = React.useCallback(() => {
    setHeight(undefined);
  }, []);

  React.useEffect(() => {
    window.addEventListener("resize", removeHeight);

    return () => {
      window.removeEventListener("resize", removeHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (height === undefined && ref.current?.getBoundingClientRect().height) {
      const debounced = debounce(resize, 500);
      debounced();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height]);

  let content = null;

  if (isNil(height)) content = <FlexLoader />;
  else if (device.most_relevant_geo_point) {
    content = (
      <ControlledDeviceMap
        data={[
          {
            id: device.id,
            smapp_id: device.smapp_id,
            name: device.name,
            geo_location: device.most_relevant_geo_point,
            detection_count: device.last_summed_detection_count,
            detection_count_delta: device.last_summed_detection_count_delta,
          },
        ]}
        center={
          new LatLng(
            device.most_relevant_geo_point.latitude,
            device.most_relevant_geo_point.longitude
          )
        }
        zoom={15}
        scrollWheelZoom={false}
      />
    );
  } else {
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Typography variant="overline">No geolocation sent yet</Typography>
    </Box>;
  }

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        height: "min(500px, calc(100vw / 3))",
        mb: 3,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="overline">Map</Typography>
      <Box ref={ref} sx={{ flex: 1 }}>
        {content}
      </Box>
    </Paper>
  );
};

export default MapPanel;

type OrchardMapPanelProps = { devices: DetailDeviceItem[] };
export const OrchardMapPanel: React.FC<OrchardMapPanelProps> = ({ devices }) => {
  const [height, setHeight] = React.useState<number | undefined>(undefined);
  const ref = React.useRef<HTMLDivElement>(null);

  const resize = React.useCallback(() => {
    setHeight(ref.current?.getBoundingClientRect().height);
  }, []);

  const removeHeight = React.useCallback(() => {
    setHeight(undefined);
  }, []);

  React.useEffect(() => {
    window.addEventListener("resize", removeHeight);

    return () => {
      window.removeEventListener("resize", removeHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (height === undefined && ref.current?.getBoundingClientRect().height) {
      const debounced = debounce(resize, 500);
      debounced();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height]);

  let content = null;

  const filteredDevices = devices.filter((device) => device.most_relevant_geo_point !== null);

  if (isNil(height)) content = <FlexLoader />;
  else if (filteredDevices.length > 0) {
    const center = filteredDevices
      .map((fd) => {
        return new LatLng(
          fd.most_relevant_geo_point!.latitude,
          fd.most_relevant_geo_point!.longitude
        );
      })
      .at(0);
    content = (
      <ControlledDeviceMap
        data={filteredDevices.map((device) => {
          return {
            id: device.id,
            smapp_id: device.smapp_id,
            name: device.name,
            geo_location: device.most_relevant_geo_point!,
            detection_count: device.last_summed_detection_count,
            detection_count_delta: device.last_summed_detection_count_delta,
          };
        })}
        center={center!}
        zoom={15}
        scrollWheelZoom={false}
      />
    );
  } else {
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Typography variant="overline">No geolocation sent yet</Typography>
    </Box>;
  }

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        height: "min(500px, calc(100vw / 3))",
        width: "100%",
        mb: 3,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="overline">Map</Typography>
      <Box ref={ref} sx={{ flex: 1 }}>
        {content}
      </Box>
    </Paper>
  );
};
