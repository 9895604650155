import LaunchIcon from "@mui/icons-material/Launch";
import React from "react";

import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useInfiniteQuery } from "@tanstack/react-query";
import useMapSettings from "hooks/useMapSettings";
import { LatLng, LatLngExpression, Icon as LeafletIcon, Map } from "leaflet";
import "leaflet/dist/leaflet.css";
import _debounce from "lodash/debounce";
import { ListLastGeolocationItem, listLastGeolocations } from "queries/devices/lastGeolocations";
import { MapContainer, MapContainerProps, Marker, Popup, TileLayer } from "react-leaflet";
import { Link } from "react-router-dom";
import ROUTES from "routes";
import { GetDetectionCountColor, LeafletColoredMarker } from "utils/commons";

const createIcon = (className: string) => {
  const icon = new LeafletIcon({
    iconUrl: "/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    className,
  });
  return icon;
};

// const SMAPP_HQ_GPS = new LatLng(47.51164, 19.0377725);

type ControlledDeviceMapProps = {
  center: LatLngExpression;
  zoom?: number;
  map?: Map | null;
  setMap?: React.Ref<Map>;
  data?: ListLastGeolocationItem[];
} & MapContainerProps;
export const ControlledDeviceMap: React.FC<ControlledDeviceMapProps> = ({
  center,
  zoom,
  map,
  setMap,
  data,
  ...props
}) => {
  return (
    <MapContainer center={center} zoom={zoom} style={{ height: "100%" }} ref={setMap} {...props}>
      <TileLayer
        attribution="Google Maps Satellite"
        url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
      />
      {(data ?? []).map(
        ({ id, smapp_id, name, geo_location: { longitude, latitude }, detection_count_delta }) => {
          const position = new LatLng(latitude, longitude);
          return (
            <Marker
              position={position}
              key={id}
              icon={createIcon(GetDetectionCountColor(detection_count_delta, LeafletColoredMarker))}
            >
              <Popup>
                <Box>
                  <Typography variant="overline">
                    Detection count: {detection_count_delta}
                  </Typography>
                </Box>
                {name && (
                  <Box>
                    <Typography variant="overline">Name: {name}</Typography>
                  </Box>
                )}
                <Box>
                  <Typography variant="overline">
                    Smapp ID: <Link to={`${ROUTES.DEVICES}/${id}`}>{smapp_id}</Link>
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="overline">
                    GPS: {latitude.toString().slice(0, -5)}, {longitude.toString().slice(0, -5)}
                    <Link
                      to={`http://www.google.com/maps/place/${latitude},${longitude}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Tooltip
                        sx={{ paddingLeft: 1.5 }}
                        placement="top"
                        title="Open coordinate in Google maps"
                      >
                        <IconButton size="small">
                          <LaunchIcon />
                        </IconButton>
                      </Tooltip>
                    </Link>
                  </Typography>
                </Box>
              </Popup>
            </Marker>
          );
        }
      )}
    </MapContainer>
  );
};

const geoKey = "list-all-last-geolocations-v2";

const DeviceMap: React.FC = () => {
  const [map, setMap] = React.useState<Map | null>(null);
  const { center, setCenter, zoom, setZoom } = useMapSettings();

  // TODO: globalize filters
  // TODO: handle loading and error properly
  const [eastWest, setEastWest] = React.useState<Pick<
    ListLastGeolocationItem,
    "east" | "west"
  > | null>();
  const [data, setData] = React.useState<ListLastGeolocationItem[]>([]);

  const debounceFn = React.useMemo(
    () =>
      _debounce((pMap: Map) => {
        const genPos = (pos: LatLng) => {
          return { lat: pos.lat, lng: pos.lng };
        };
        setEastWest({
          east: genPos(pMap.getBounds().getNorthEast()),
          west: genPos(pMap.getBounds().getSouthWest()),
        });
      }, 1000),
    []
  );

  React.useEffect(() => {
    if (map) {
      const onMove = () => {
        setCenter(map.getCenter());
        debounceFn(map);
      };
      onMove();

      const onZoom = () => {
        setZoom(map.getZoom());
        debounceFn(map);
      };
      onZoom();

      map.on("move", onMove);
      map.on("zoom", onZoom);

      return () => {
        map.off("move", onMove);
        map.off("zoom", onZoom);
      };
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  const infiGeo = useInfiniteQuery({
    queryKey: [geoKey, eastWest],
    queryFn: async ({ pageParam = 1 }) => {
      const { data, meta } = await listLastGeolocations({
        page: pageParam,
        // page_size: 6,
        active: true,
        ...eastWest,
        page_size: 35,
      });
      return { data, meta };
    },
    enabled: eastWest !== undefined && eastWest !== null,
    getNextPageParam: (lastPage) => (lastPage.meta.next ? lastPage.meta.page + 1 : undefined),
    staleTime: Infinity,
    cacheTime: Infinity,
    keepPreviousData: true,
  });

  const newData = (infiGeo.data?.pages || []).map((v) => v.data).flat();

  React.useEffect(() => {
    if (newData.length > 0) {
      const appendData = newData.filter((v) => !data.map((vv) => vv.id).includes(v.id));
      if (appendData.length > 0) {
        setData((pre) => [...pre, ...appendData]);
      }
    }
  }, [newData, data]);

  React.useEffect(() => {
    if (infiGeo.hasNextPage) {
      infiGeo.fetchNextPage();
    }
  }, [infiGeo]);

  return (
    <ControlledDeviceMap
      data={data}
      center={center}
      zoom={zoom}
      map={map}
      setMap={setMap}
    />
  );
};

export default DeviceMap;
