import axios from "axios";

export type ListDeviceDeltaCountItem = {
  corporate_name: string | null;
  smapp_id: string;
  latitude: string | null;
  longitude: string | null;
  date: string;
  detection_count: number | null;
  detection_count_delta: number | null;
};

export type ExportDeltaCountItem = {
  start_date: string;
  end_date: string;
  preview?: boolean;
  content_type?: "application/json" | "text/csv";
};

// New merged function, which handles both json and csv type
export const fetchDeviceDeltaExportData = async (
  params: ExportDeltaCountItem,
  responseType: "json" | "csv" = "json"
): Promise<Blob | ListDeviceDeltaCountItem[]> => {
  params.content_type = responseType === "csv" ? "text/csv" : "application/json";

  const { data } = await axios.get(`/api/devices/export-device-delta-count`, {
    params,
    responseType: responseType === "csv" ? "blob" : "json",
  });

  return responseType === "csv"
    ? new Blob([data], { type: "text/csv" })
    : (data as ListDeviceDeltaCountItem[]);
};

export const exportDeviceDelta = async (params: ExportDeltaCountItem): Promise<Blob> => {
  return (await fetchDeviceDeltaExportData(params, "csv")) as Blob;
};

export const listDevicesDeltaCount = async (
  params: ExportDeltaCountItem
): Promise<ListDeviceDeltaCountItem[]> => {
  return (await fetchDeviceDeltaExportData(params)) as ListDeviceDeltaCountItem[];
};

export default exportDeviceDelta;
