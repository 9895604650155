import React, { useState } from "react";
import { 
    TableRow, 
    TableCell, 
    IconButton, 
    Box, 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle 
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { ReactComponent as ValidatorDeviceIcon } from '../../../icons/validator_device.svg';

interface ValidatorItemProps {
    name: string;
    numberOfDevices: number;
    handleDeleteClick: () => void;
    handleAssignDevicesClick: () => void;
}

const ValidatorItem: React.FC<ValidatorItemProps> = ({
    name,
    numberOfDevices,
    handleDeleteClick,
    handleAssignDevicesClick
}) => {
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDelete = () => {
        handleDeleteClick();
        setOpenDialog(false);
    };

    return (
        <>
            <TableRow sx={{ 
                height: 100,
                '& .MuiTableCell-root': { 
                    borderBottom: '1px solid #D4D7D5',
                    fontSize: '16px',
                }
            }}>
                <TableCell sx={{ pl: 4 }}>{name}</TableCell>
                <TableCell sx={{ pl: 4 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ValidatorDeviceIcon />
                        <Box component="span" sx={{ ml: 1 }}>{numberOfDevices}</Box>
                    </Box>
                </TableCell>
                <TableCell align="right" sx={{ pr: 2, pl: 4 }}>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={handleAssignDevicesClick}
                        sx={{ mr: 1, fontSize: 12, height: '36px' }}
                    >
                        Manage devices
                    </Button>
                    <IconButton onClick={handleOpenDialog}>
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>

            {/* Confirmation Dialog */}
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete {name}? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ValidatorItem;
