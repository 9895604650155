import { useQuery } from "@tanstack/react-query";
import { IMAGE_TYPES, ImageCoordinates, downloadImage } from "queries/devices/images";
import React from "react";
import { SECONDS_30 } from "utils/time";
import { Typography } from "@mui/material";
import ValidationProvider, { ValidationProviderProps } from "context/ValidationProvider";
import ImageEditor from "./ImageEditor";
import LoadingImage from "./LoadingImage";

export type ImageProps = {
  id: string;
  title?: string;
  initialCoordinates: ImageCoordinates;
  // The image edit has two modes: validation and delta
  // Validation mode is the one mostly used. It also marks images as validated and saves the
  // marked bounding boxes and count of bounding boxes (detection count).
  mode: "validation" | "delta";
  isVisible?: boolean;
} & Pick<ValidationProviderProps, "saveAs" | "onClose">;

export const ImageEdit: React.FC<ImageProps> = ({
  id,
  initialCoordinates,
  onClose,
  saveAs,
  title,
  mode,
  isVisible,
}) => {
  const { data, isFetching, error } = useQuery({
    // always start with raw image, we draw coordinates on it, don't use processed image
    // for that as in that case we won't be able to delete bounding boxes.
    queryFn: () => downloadImage({ id: id as string, type: IMAGE_TYPES.raw }),
    queryKey: ["download-image", { id, type: IMAGE_TYPES.raw }],
    staleTime: SECONDS_30,
    enabled: !!id,
  });

  let content = null;
  if (isFetching) {
    content = <LoadingImage showTitle={!!title} />;
  } else if (data) {
    content = (
      <ValidationProvider
        id={id}
        saveAs={saveAs}
        onClose={onClose}
        initialCoordinates={initialCoordinates}
        mode={mode}
        isVisible={isVisible}
      >
        <ImageEditor data={data} />
      </ValidationProvider>
    );
  } else {
    content = <>{String(error)}</>;
  }

  return (
    <div className="image">
      {title && <Typography variant="overline">{title}</Typography>}
      {content}
    </div>
  );
};
