import { useState } from "react";
import { approveStatusChange, updateDeviceStatus } from "queries/devices";
import { DeviceDetailedStatus } from "utils/device-statuses";
import { SelectChangeEvent } from "@mui/material";

export const useDeviceStatus = (deviceId: string, reloadDeviceDetails: () => void) => {
  const [selectedStatus, setSelectedStatus] = useState<DeviceDetailedStatus>(
    "not_installed" as DeviceDetailedStatus
  );
  const [isStatusChanged, setIsStatusChanged] = useState<boolean>(false);
  const [isStatusSaved, setIsStatusSaved] = useState<boolean>(false);

  const changeDeviceStatus = async (newStatus: DeviceDetailedStatus) => {
    await updateDeviceStatus({ id: deviceId, status: newStatus });
    setIsStatusChanged(false);
    setIsStatusSaved(true);
  };

  const handleDropDownStatusChange = (event: SelectChangeEvent<DeviceDetailedStatus>) => {
    setSelectedStatus(event.target.value as DeviceDetailedStatus);
    setIsStatusChanged(true);
    setIsStatusSaved(false);
  };

  const handleUpdateStatusClick = async () => {
    await changeDeviceStatus(selectedStatus);
    reloadDeviceDetails();
  };

  const handleApproveStatusClick = async () => {
    await approveStatusChange({ deviceId });
    reloadDeviceDetails();
  };

  return {
    selectedStatus,
    setSelectedStatus,
    handleDropDownStatusChange,
    isStatusChanged,
    isStatusSaved,
    handleUpdateStatusClick,
    handleApproveStatusClick,
  };
};

export default useDeviceStatus;
