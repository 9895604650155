import * as Yup from "yup";

// TO DO handle loading state properly
type UpdateDeviceSettingsFormValues = {
  target_lure_type: string | null;
  target_lure_days_life_span: number | null;
  target_lure_last_replaced: number | null;

  target_pest: string | null;
  target_crop: string | null;
  target_config: string | null;
  target_commission_status: string | null;
  target_firmware: string | null;
};
export const schema = Yup.object({
  target_lure_type: Yup.string().nullable(),
  target_lure_days_life_span: Yup.number().nullable(),
  target_lure_last_replaced: Yup.number().nullable(),

  target_pest: Yup.string().nullable(),
  target_crop: Yup.string().nullable(),
  target_config: Yup.string().nullable(),
  target_commission_status: Yup.string().nullable(),
  target_firmware: Yup.string().nullable(),
});

export type { UpdateDeviceSettingsFormValues };
