import React from "react";
import { Route, Routes } from "react-router-dom";
import AppWrapper from "components/AppWrapper";
import ProtectedRoute from "components/ProtectedRoute";
import ValidatorManagementMain from "./main";

const ValidatorManagement: React.FC = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <ProtectedRoute>
            <AppWrapper mainSxProps={{ width: "100%", maxWidth: "100%" }}>
              <ValidatorManagementMain />
            </AppWrapper>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default ValidatorManagement;
