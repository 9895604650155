import React, { useCallback, useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ListImageItem } from "queries/devices/images";
import useImageGridPanel from "./useImageGridPanel";
import { FullImageModalV2 } from "../FullImageModal";
import ImageGridItem from "./ImageGridItem";

interface ImageGridProps {
  deviceID: string;
  onStartValidation: (image: ListImageItem) => void;
}

const styles = {
  container: {
    boxShadow: 2,
    borderRadius: "10px",
    padding: "16px",
    backgroundColor: "white",
  },
};

// Component for the right panel (Image Grid)
const ImageGridPanel: React.FC<ImageGridProps> = ({ deviceID, onStartValidation }) => {
  const { imagesQuery, selectedMonth, handleMonthChange, handleImageSelect } =
    useImageGridPanel(deviceID);

  const [hoveredImageId, setHoveredImageId] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState("");

  const handleOpenModal = useCallback((imageId: string) => {
    setSelectedImageId(imageId);
    setModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <Grid item xs={12} md={7}>
      <Box sx={styles.container}>
        <Box
          sx={{
            flexDirection: "column",
            marginBottom: "32px",
            marginLeft: "16px",
          }}
        >
          <Typography variant="subtitle1" sx={{ marginBottom: "8px", fontWeight: "bold" }}>
            Selected month:
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={["year", "month"]}
              value={selectedMonth}
              // TODO: maybe the year the corp registered?
              minDate={new Date("2020-01-01")}
              maxDate={new Date()}
              onChange={(newDate) => handleMonthChange(newDate)}
              slotProps={{ textField: { size: "small" } }}
            />
          </LocalizationProvider>
        </Box>
        <Box
          sx={{
            maxHeight: "64vh",
            overflowY: "auto",
            paddingRight: "16px",
            paddingLeft: "16px",
            paddingTop: "12px",
          }}
        >
          <Grid container spacing={2}>
            {imagesQuery.data?.map((image) => (
              <ImageGridItem
                key={image.id}
                image={image}
                hoveredImageId={hoveredImageId}
                onSetHoveredImageId={setHoveredImageId}
                onHandleImageSelect={handleImageSelect}
                onHandleStartValidation={onStartValidation}
                onHandleOpenModal={handleOpenModal}
              />
            ))}
          </Grid>
        </Box>
      </Box>
      <FullImageModalV2 open={modalOpen} onClose={handleCloseModal} imageId={selectedImageId} />
    </Grid>
  );
};

export default ImageGridPanel;
