import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

interface DatePickersProps {
    onDateChange?: (dates: { fromDate: string, toDate: string }) => void;
}

const DatePickers: React.FC<DatePickersProps> = ({ onDateChange }) => {
    const getDefaultFromDate = () => {
        const date = new Date();
        date.setDate(date.getDate() - 90);
        return date.toISOString().split('T')[0];
    };

    const [fromDate, setFromDate] = useState<string>(getDefaultFromDate());
    const [toDate, setToDate] = useState<string>(new Date().toISOString().split('T')[0]);

    const handleFromDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const date = event.target.value;
        setFromDate(date);
        if (onDateChange) onDateChange({ fromDate: date, toDate });
    };

    const handleToDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const date = event.target.value;
        setToDate(date);
        if (onDateChange) onDateChange({ fromDate, toDate: date });
    };

    return (
        <Box sx={{ display: 'flex', gap: 2, flexGrow: 1 }}>
            <TextField
                label="From"
                type="date"
                value={fromDate || ''}
                onChange={handleFromDateChange}
                InputLabelProps={{ shrink: true }}
                placeholder="mm/dd/yyyy"
                sx={{
                    flexGrow: 1,
                    maxWidth: 400,
                    bgcolor: '#FFFFFF',
                    borderRadius: 1,
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#E0E0E0',
                        },
                        '&:hover fieldset': {
                            borderColor: '#A3A3A3',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#A3A3A3',
                        },
                    },
                }}
            />
            <TextField
                label="To"
                type="date"
                value={toDate || ''}
                onChange={handleToDateChange}
                InputLabelProps={{ shrink: true }}
                placeholder="mm/dd/yyyy"
                sx={{
                    flexGrow: 1,
                    maxWidth: 400,
                    bgcolor: '#FFFFFF',
                    borderRadius: 1,
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#E0E0E0',
                        },
                        '&:hover fieldset': {
                            borderColor: '#A3A3A3',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#A3A3A3',
                        },
                    },
                }}
            />
        </Box>
    );
};

export default DatePickers;
