import { Box, Button, Grid } from "@mui/material";
import { ImageView } from "components/Image/ImageView";
import React from "react";
import { CheckOutlined } from "@mui/icons-material";
import { IMAGE_TYPES, ListImageItem } from "queries/devices/images";
import ValidatedImageOverlay from "./ValidatedImageOverlay";
import VisibilityIssueImageOverlay from "./VisibilityIssueImageOverlay";

interface ImageGridItemProps {
  image: ListImageItem;
  hoveredImageId: string | null;
  onSetHoveredImageId: (imageId: string | null) => void;
  onHandleImageSelect: (imageId: string) => void;
  onHandleStartValidation: (image: ListImageItem) => void;
  onHandleOpenModal: (imageId: string) => void;
}

const ImageGridItem: React.FC<ImageGridItemProps> = ({
  image,
  hoveredImageId,
  onSetHoveredImageId,
  onHandleImageSelect,
  onHandleStartValidation,
  onHandleOpenModal,
}) => {
  const {
    id: imageID,
    detection_count: detectionCount,
    detection_count_delta: detectionCountDelta,
    is_visible: isVisible,
    is_validated: isValidated,
  } = image;

  return (
    <Grid item key={imageID}>
      <Box
        sx={{
          position: "relative",
          overflow: "visible",
          borderRadius: "8px",
          aspectRatio: "5/4",
          border: isValidated ? "2px solid black" : "2px solid #F4B71D",
        }}
        onMouseEnter={() => onSetHoveredImageId(imageID)}
        onMouseLeave={() => onSetHoveredImageId(null)}
        onClick={() => onHandleImageSelect(imageID)}
      >
        <Box
          sx={{
            position: "relative",
            borderRadius: "8px",
            overflow: "hidden",
            cursor: "pointer",
            height: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            aspectRatio: "5/4",
          }}
        >
          <ImageView id={imageID as string} type={IMAGE_TYPES.raw} stretchy />
          {isValidated && (
            <ValidatedImageOverlay
              catchCount={detectionCount}
              catchCountDelta={detectionCountDelta}
            />
          )}
          {!isVisible && <VisibilityIssueImageOverlay />}
          {hoveredImageId === imageID && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                zIndex: 10,
              }}
            >
              <Button
                variant="contained"
                size="small"
                sx={{ width: "90%" }}
                onClick={(e) => {
                  e.stopPropagation();
                  onHandleStartValidation(image);
                }}
              >
                Validate
              </Button>
              <Button
                variant="outlined"
                size="small"
                sx={{ width: "90%" }}
                onClick={(e) => {
                  e.stopPropagation();
                  onHandleOpenModal(imageID as string);
                }}
              >
                View
              </Button>
            </Box>
          )}
        </Box>
        {isValidated && (
          <CheckOutlined
            sx={{
              position: "absolute",
              top: "-8px",
              right: "-8px",
              color: "#FFFFFF",
              zIndex: 3,
              backgroundColor: "#F4B71D",
              borderRadius: "50%",
              padding: "4px",
            }}
          />
        )}
      </Box>
    </Grid>
  );
};

export default ImageGridItem;
