import { useState, useCallback } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useAuth } from "context/AuthProvider";
import { RegisterParams, registration, USER_TYPE } from "queries/users";
import { listValidators} from "queries/validators";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export interface Validator {
  uuid: string;
  name: string;
  numberOfDevices: number;
}

export interface ValidatorFormData {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

const schema = Yup.object({
  username: Yup.string().required("Username is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const useValidatorManagement = () => {
  const [selectedValidatorId, setSelectedValidatorId] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [openRegModal, setOpenRegModal] = useState(false);
  const [openDevicesManageModal, setOpenDevicesManageModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { isRegistrationInProgress } = useAuth();
  const [registerError, setRegisterError] = useState<AxiosError | null>(null);
  const { deleteUser } = useAuth();
  const [showDeleteError, setShowDeleteError] = useState(false);
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ValidatorFormData>({
    resolver: yupResolver(schema),
  });

  const fetchValidators = useCallback(async (page: number, search: string) => {
    try {

      const response = await listValidators(
        {
          page,
          page_size: 10,
          search
        }
      );

      const validators: Validator[] = response.data.map(validator => ({
        uuid: validator.id,
        name: `${validator.username}`,
        numberOfDevices: validator.devices_count,
      }));

      return { validators, nextPage: response.meta.next ? page + 1 : null };
    } catch (error) {
      console.error('Error fetching validators:', error);
      throw error;
    }
  }, []);

  const handleOpenRegModal = () => setOpenRegModal(true);
  const handleCloseRegModal = () => {
    setOpenRegModal(false);
    reset();
    setRegisterError(null);
  };

  const handleOpenDevicesManageModal = (id: string | null) => {
    if (id) {
      setSelectedValidatorId(id);
      setOpenDevicesManageModal(true);
    }
  };
  const handleCloseDevicesManageModal = () => {
    setOpenDevicesManageModal(false);
    setSelectedValidatorId(null);
    queryClient.invalidateQueries(['list-validators']);
  };

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  const registerMutation = useMutation<any, AxiosError, RegisterParams, any>({
    mutationFn: registration, 
  });

  const handleAddValidator = async ({ username, password, email, firstName, lastName }: ValidatorFormData): Promise<string | null> => {
    try {
      const { id: newValidatorUuid } = await registerMutation.mutateAsync({
        username,
        password,
        email,
        first_name: firstName,
        last_name: lastName,
        type: USER_TYPE.VALIDATOR,
      });

      handleCloseRegModal();
      
      queryClient.invalidateQueries(['list-validators']);

      return newValidatorUuid;
    } catch (error) {
      
      if (error instanceof AxiosError) {
        setRegisterError(error);
      }
      return null;
    }
  };

  const handleSaveAndClose = handleSubmit(async (data) => {
    try {
      await handleAddValidator(data);
    } catch (error) {
      console.error('Registration error:', error);
    }
  });

  const handleSaveAndAssignDevices = handleSubmit(async (data) => {
    try {
      const uuid = await handleAddValidator(data);
      handleOpenDevicesManageModal(uuid);
    } catch (error) {
      // Error handling
    }
  });

  const handleDeleteValidator = async (uuid: string) => {
    try {
      await deleteUser(uuid);
      queryClient.clear();
      queryClient.refetchQueries(['list-validators']);
    } catch (error) {
      setShowDeleteError(true);
      console.error('Error deleting validator:', error);
    }
  };
  return {

    fetchValidators,
    searchTerm,
    selectedValidatorId,
    openRegModal,
    openDevicesManageModal,
    showPassword,
    register,
    errors,
    setSearchTerm,
    handleOpenRegModal,
    handleCloseRegModal,
    handleOpenDevicesManageModal,
    handleCloseDevicesManageModal,
    handleClickShowPassword,
    handleSaveAndClose,
    handleSaveAndAssignDevices,
    isRegistrationInProgress,
    registerError,
    handleDeleteValidator,
    showDeleteError,
    setShowDeleteError
  };
};
