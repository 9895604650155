import React from "react";
import { Alert, Box, Button, Card, TextField, MenuItem } from "@mui/material";
import { AxiosError } from "axios";
import useCustomSnackBar from "hooks/useCustomSnackBar";

import { useForm, SubmitHandler, FormProvider, Controller } from "react-hook-form";
import PasswordInput from "components/PasswordInput";
import { registration, USER_TYPE } from "queries/users";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import UserTable, { qKey } from "./userTable";

type FWFormFieldsType = { label: string; inputType: string; comp?: any };

const inputs = [
  {
    type: "manual",
    name: "password1",
    message: "Passwords do not match",
  },
  {
    type: "manual",
    name: "password2",
    message: "Passwords do not match",
  },
];

type UserMngmntSubmitType = {
  name: string;
  email: string;
  password1: string;
  password2: string;
  corporateName: string;
  type: USER_TYPE;
};

type UserMngmntInputType = {
  name: FWFormFieldsType;
  email: FWFormFieldsType;
  password1: FWFormFieldsType;
  password2: FWFormFieldsType;
  corporateName: FWFormFieldsType;
  type: FWFormFieldsType;
};

const defaultUserInput: UserMngmntInputType = {
  name: { label: "Username", inputType: "string", comp: TextField },
  email: { label: "User email", inputType: "email", comp: TextField },
  password1: { label: "Password", inputType: "password", comp: PasswordInput },
  password2: { label: "Password again", inputType: "password", comp: PasswordInput },
  corporateName: { label: "Corporate name", inputType: "string", comp: TextField },
  type: { label: "User Type", inputType: "select", comp: TextField },
};

const UserManagement = () => {
  const queryClient = useQueryClient();

  const { jsx: theSnack, setErrorList, setSuccessList } = useCustomSnackBar();

  const registerMutation = useMutation<any, AxiosError, any, any>({
    mutationFn: registration,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [qKey] });
      setSuccessList((pre) => [...pre, "Register succeeded"]);
    },
    onError: (v) => {
      const obj = Object.values(v.response?.data as any).flat();
      setErrorList((pre) => [...pre, ...(obj as string[])]);
    },
  });

  const methods = useForm<UserMngmntSubmitType>();
  const onSubmit: SubmitHandler<UserMngmntSubmitType> = (data) => {
    if (data.password1 !== data.password2) {
      inputs.forEach(({ name, type, message }) => {
        methods.setError(name as keyof UserMngmntInputType, { type, message });
      });
      return;
    }
    registerMutation.mutate({
      username: data.name,
      email: data.email,
      corporation_name: data.corporateName,
      password: data.password1,
      type: data.type,
    });
  };

  const userRegistrationFontSize = { "& .MuiInputBase-input": { fontSize: 10 } };
  const userRegistration = (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box sx={{ scale: 0.5, width: "100%", display: "flex", flexDirection: "column", gap: 1.5 }}>
          {Object.entries(defaultUserInput).map(([k, v]) => {
            const fieldErrors = methods.formState.errors[k as keyof UserMngmntInputType];
            return (
              <Box key={k}>
                <Controller
                  name={k}
                  render={({ field: { value, ...rest } }) => {
                    return (
                      <v.comp
                        {...rest}
                        sx={userRegistrationFontSize}
                        required
                        value={value || ""}
                        label={v.label}
                        variant="filled"
                        fullWidth
                        size="small"
                        type={v.inputType}
                        select={k === 'type'}
                      >
                        {k === 'type' && Object.values(USER_TYPE).map((type) => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </v.comp>
                    );
                  }}
                />
                {fieldErrors && (
                  <Alert severity="error">
                    <span>{fieldErrors.message}</span>
                  </Alert>
                )}
              </Box>
            );
          })}
          <Button variant="contained" type="submit" size="small" style={{ fontSize: "10px" }}>
            Register
          </Button>
        </Box>
      </form>
    </FormProvider>
  );

  return (
    <Box>
      {theSnack}
      <Box sx={{ display: "flex", width: "100%", gap: 0.25 }}>
        <Card
          className="UserRegistrationContainer"
          sx={{ p: 1, m: 1, flex: 1, height: "fit-content" }}
        >
          {userRegistration}
        </Card>
        <Card sx={{ p: 1, m: 1, gap: 1, flex: 5 }}>
          <UserTable />
        </Card>
      </Box>
    </Box>
  );
};

export default UserManagement;
