import * as React from "react";
import AppWrapper from "components/AppWrapper";
import ROUTES from "routes";
import { Navigate } from "react-router-dom";
import { useAuth } from "context/AuthProvider";
import { USER_TYPE } from "queries/users";

const HomeMain: React.FC = () => {
  const { userType } = useAuth();

  const getHomeRoute = () => {
    if (userType === USER_TYPE.VALIDATOR) {
      return ROUTES.VALIDATOR_DEVICES;
    }
    return ROUTES.DEVICES;
  };

  return (
    <AppWrapper>
      <Navigate to={getHomeRoute()} replace />
    </AppWrapper>
  );
};

export default HomeMain;
