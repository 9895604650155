import React from "react";
import { Box, Button, IconButton } from "@mui/material";
import { useValidation } from "context/ValidationProvider";
import { useChainValidation } from "context/ChainValidationProvider";

import { Close, Image, Visibility, VisibilityOff } from "@mui/icons-material";

const ToolbarTop = () => {
  const { onClose, onChangeVisibility, isVisible } = useValidation();
  const { isChainValidation, currrentImageIndex, allImageCount } = useChainValidation();

  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <Box>
        {isVisible ?? true ? (
          <Button variant="outlined" size="small" sx={{ m: 1 }} onClick={onChangeVisibility}>
            REPORT NOT VISIBLE <VisibilityOff sx={{ ml: 1 }} />
          </Button>
        ) : (
          <Button variant="outlined" size="small" sx={{ m: 1 }} onClick={onChangeVisibility}>
            REMOVE VISIBILITY REPORT <Visibility sx={{ ml: 1 }} />
          </Button>
        )}
      </Box>

      {isChainValidation && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Image sx={{ mr: 0.5 }} /> {currrentImageIndex + 1} / {allImageCount}
        </Box>
      )}

      <IconButton aria-label="close" size="small" color="inherit" onClick={onClose}>
        <Close />
      </IconButton>
    </Box>
  );
};

export default ToolbarTop;
